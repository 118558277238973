.mapboxgl-canvas {
  width: 100% !important;
  // height: 100% !important;
  transition: ease-in-out;
}

.mapboxgl-ctrl-attrib {
  display: none;
}

a.mapboxgl-ctrl-logo {
  width: 88px;
  height: 23px;
  margin: 0 0 -4px -4px;
  display: block;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  background-image: url("../../assets/icons/svg/constants/mapbox-logo.svg");
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
  width: 23px;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // padding: 0  auto;
  // margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;




  .mapboxgl-popup-close-button {
    display: none;
  }

  .mapboxgl-popup-content {
    font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', sans-serif;
    padding: 0;
    max-width: 170px;
    min-width: 150px;
    // min-height: 130px;
    // max-height: 150px;
    min-height: 50px;
    // height:125px;
    // max-height: 150px;
    border-radius: 10px;

  }

  .mapboxgl-popup-content h6 {
    // background-color: rgba(22,95,89,1);
    @extend .gadget-title;
    text-transform: uppercase;
    font-size: 13px;
    margin: 0;
    padding: 10px;
    // margin-top: -15px;
    border-radius: 10px 10px 0px 0px;
  }

  .mapboxgl-popup-content p {
    // @extend .gadget-subtitle  ;
    font-size: 10px;
    // padding-top: 15px;
    align-items: center;
    // justify-content: space-around; 
  }

  // .mapboxgl-popup-content div {
  //   // padding: 1px;
  //   display: flex;
  //   @extend .gadget-subtitle  ;
  //   font-size: 10px;


  // }

  .mapboxgl-popup-anchor-top>.mapboxgl-popup-content {
    margin-top: 15px;
  }

  .mapboxgl-popup-anchor-bottom>.mapboxgl-popup-tip {
    display: none;
  }


  .HoverPlot-Content .mapboxgl-popup-content div {
    @extend .gadget-subtitle;
    font-size: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  }

  .HoverPlot-Map .mapboxgl-popup-content div {
    @extend .gadget-subtitle;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px;
  }

  // &.mapboxgl-map{
  //   //height: 29.2em;
  //   height: 39em;
  // }
}

// .subTitleIrrigation{
//   @extend .gadget-subtitle ;
//   margin-top: -4px;
//   margin-inline-start: 8px;
// }

.subTitleSensor,
.subTitleIrrigation {
  @extend .gadget-subtitle;
  // margin-top: -2px;
  // margin-inline-start: 12px;
  // margin-top: -19px;
  margin-bottom: 1em;
  margin-inline-start: 2px;
  // position: fixed;
  z-index: 1029;
  // &::after{
  //   content: "---";
  //   background-color: #04403a;
  //   width: 105%;
  //   position: fixed;
  //   margin-top: 10px;
  //   // margin-left: -37px;
  //   margin-left: -180px;
  //   z-index: -1;
  //   height: 12px;
  //   color: transparent;
  // }
}

// @media (min-width: 2300px) and (max-width:2900px){
//   .subTitleSensor, .subTitleIrrigation{
//     &::after{
//       margin-top: 7px !important;
//       height: 14px;
//     }
//   }
// }

// @media (min-width: 2900px){
//   .subTitleSensor, .subTitleIrrigation{
//     &::after{
//       margin-top: 2px !important;
//       height: 18px;
//     }
//   }
// }