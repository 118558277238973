.multiview-calendar-card {
    height: 46.2em
}

.calendar-schedule-card {
    position: relative;

    .failure-container {
        background: #A54747 0% 0% no-repeat padding-box !important;
        color: #FFC6C6 !important;
    }

    .modal-body-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .cancel-event-header {
            text-transform: uppercase;
            color: #C4FFF9;
            margin-top: 1em;
            margin-bottom: 0.3em;
        }

        .cancel-event-subheader {
            color: #46AAA0;
            margin-bottom: 3em;
            text-align: center;
            padding-inline: 0.5em;
            max-width: 90%;
        }

        label {
            font-size: 1em;
            letter-spacing: 0.18px;
        }
    }

    .create-event-title {
        //color: #C4FFF9;
        padding-top: 1em;
        //font-size: 1em;
        padding-inline-start: 2em;
        @extend .title;

        form .input-group {
            margin-top: 30px !important;
            margin-bottom: 0 !important;
            text-transform: capitalize;
        }

        .title-error {
            color: #F48585;
            margin-inline-start: 1.25em;
        }

        // text-transform: uppercase;
    }

    .input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .bullet {
            width: 0.75em;
            height: 0.75em;
            border-radius: 0.375em;
            margin-inline-end: 0.5em;
            margin-top: 30px !important;
        }
    }

    .delete-options-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 3%;

        .radio-button {
            background-color: #03403A;
            -webkit-appearance: none;
            box-shadow: $dark-button-box-shadow;
            -webkit-box-shadow: none;
        }

        .radio-button-element {
            margin-bottom: 5%;
        }
    }
}


.create-event-title-input {
    padding-top: 0 !important;
    width: 100%;

    form {
        width: 60%;
    }
}

.event-types-container {
    padding-block-start: 10%;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-evenly;
    overflow-y: scroll;

    .card-with-icon-container {
        background-color: #065A53;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 15em;
        height: 8em;
        -webkit-appearance: none;
        margin-bottom: 10%;
        //box-shadow: $dark-button-box-shadow;
        $lower-shadow: 4px 4px 8px darken($accordion-bg, 3%);
        $upper-shadow: -3px -3px 6px lighten($accordion-bg, 4%);
        box-shadow: $upper-shadow, $lower-shadow !important;
        -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
        border: none;

        .icon-container {
            left: 5.5em;
            top: -1.5em;
        }

        button {
            width: 100%;
            height: 100%;
            background-color: transparent;
            outline: none;
            border: none;
            color: #73E4CC;
            text-transform: uppercase;
            letter-spacing: 0.18px;
            font-weight: 400;

        }
    }

    .card-with-icon-container:hover {
        background-color: #73E4CC;
        border: 3px solid #C4FFF9;
        border-radius: 5px;

        button {
            color: #065A53;
            font-weight: 500;
        }
    }
}

.new-event-filters {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
    position: relative;

    @mixin shadow($neuo) {
        -webkit-appearance: none;
        $lower-shadow: 4px 4px 10px darken($neuo, 5%);
        $upper-shadow: -4px -4px 5px lighten($neuo, 7%);
        box-shadow: $upper-shadow, $lower-shadow;
        -webkit-box-shadow: $upper-shadow, $lower-shadow;
    }

    .neuo {
        border-radius: 5px;
        border-style: hidden;
        background: $main_layer;
        @include shadow($main_layer);
        cursor: pointer;
    }

    .reminder-wrapper-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        height: 77vh;

        .btn {
            label {
                font-size: 1em;
            }
        }
    }

    .crop-event-panel-content,
    .reminder-filters-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-inline: 1em;

        .Map-Container {
            .Title {
                margin-bottom: 0.1em !important;
            }

            .SubTitle {
                margin-bottom: 0.5em !important;
            }

            .Selected-Valves {
                flex: 1 !important
            }
        }

        .selection-container {
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            height: 77vh;
            margin-top: 1.4em;
            padding-bottom: 15%;

            .radio-button-label {
                color: #73E4CC;
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            }

            .btn-primary label {
                font-size: 0.9rem;
            }
        }

        .tabs-container {
            display: flex;
            flex-direction: row;

            .tab-button,
            .disable-tab-button {
                position: relative;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                -webkit-appearance: none;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                border-radius: 5px;
                outline: none !important;
                background-color: $tab-background;
                margin-inline-end: 2em;

                .tab-icon {
                    display: flex;
                    flex: 1;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    #farm {
                        width: 70%;
                        height: 70%;
                    }
                }

            }

            .disable-tab-button:hover {
                display: block;
            }

            // .disable-tab-button:hover:after{
            //     background-size: 500px 500px;
            //     background: $blocked-tab-background url("../../assets/icons/svg/constants/disabled.svg") no-repeat center;
            //     opacity: 0.6;    
            //     position: absolute; 
            //     left: 0;
            //     top: 0;  
            //     content: "";
            //     width: 100%;
            //     height: 100%;
            // }
            .selected {
                background-color: $primary !important;
                border: 2px solid $primary-txt;
            }

            .tab-button:hover {
                background-color: $primary;
                border: 2px solid $primary-txt;
                transition: 0.3s ease-out;
            }
        }

        .tab-title {
            color: #C4FFF9;
            text-transform: uppercase;
            //font-size: 0.9em;
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            font-weight: 400;
            // margin-top:1.4em;
        }

        .tab-subtitle {
            color: #46AAA0;
            text-transform: capitalize;
            //font-size: 0.9em;
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            font-weight: 400;
            margin-bottom: 2em;
        }

        .date-duration-container {
            display: flex;
            flex-direction: column;
            position: relative;

            .date-duration-button {
                border: none;
                outline: none;
                background-color: #0D4944;
                -webkit-appearance: none;
                box-shadow: 3px 3px 6px #032925, -0.5px -0.5px 5px #73e4cc40;
                -webkit-box-shadow: 3px 3px 6px #032925, -0.5px -0.5px 5px #73e4cc40;
                width: 16em;
                height: 2.5em;
                border-radius: 5px;
                margin-bottom: 1em;
                margin-top: 0.5em;
                position: relative;

                span {
                    margin-inline-start: 0;
                    color: #73E4CC;
                }
            }

            .date-duration-label {
                //text-transform: uppercase;
                //color: #C4FFF9;
                //font-size: 0.9em;
                //font-weight: 400;
                @extend .title
            }

            .date-duration-button::before {
                content: url('../../assets/icons/svg/MainMap/calendar.svg');
                width: 2em;
                height: 1.5em;
                z-index: 1000 !important;
                position: absolute;
                left: 0.3em;
                top: 0.75em;
            }

            .triangle {
                position: absolute;
                width: 0;
                height: 0;
                top: 4.5em;
                left: 20%;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 12px solid $light-container-background;
                z-index: 1000 !important;
            }

            .calendar {
                // transform:scale(0.7,0.7);
                position: absolute;
                left: 0.2em;
                top: 5em;
                z-index: 3000 !important;

                .date-picker-container {
                    width: 15em;
                    background-color: $light-container-background !important;
                }

                .DayPicker-Months {
                    background-color: $light-container-background !important;
                }

                .DayPicker-Day {
                    color: #73E4CC;
                }
            }
        }

        .specifications-container {
            width: 100%;
            // height: 51vh;
            // overflow-y: scroll;
            background-color: #0A2B2766;
            border-radius: 5px;
            align-self: center;
            padding: 1.5em;
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));

            .error {
                color: #F48585;
                margin-block: 10px;
            }

            // margin-bottom: 10%;

            .calendar-dosage-container {
                form .input-group {
                    margin: 10px 0 !important;
                }

                .dosage-container .error {
                    position: relative !important;
                }

                .input-container {
                    flex-direction: column !important;
                    align-items: flex-start !important;
                }

            }

            .time-pickers-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                // .end-time-container{
                //     margin-inline-start: 10%;
                // }
                .start-time-container {
                    margin-inline-end: 10%;
                }

                .start-time-container,
                .end-time-container {
                    button {
                        border: none;
                        outline: none;
                        background-color: #0D4944;
                        -webkit-appearance: none;
                        box-shadow: 3px 3px 6px #032925, -0.5px -0.5px 5px #73e4cc40;
                        -webkit-box-shadow: 3px 3px 6px #032925, -0.5px -0.5px 5px #73e4cc40;
                        width: 100%;
                        height: 2.5em;
                        border-radius: 5px;
                        // margin-bottom: 1em;
                        margin-top: 0.5em;
                        display: flex;
                        align-items: center;

                        span {
                            padding-inline-start: 1em;
                        }
                    }

                    margin-bottom: 1em;
                }
            }

            .repetition-label,
            .start-time-label,
            .end-time-label,
            .date-duration-label,
            .occurrence-label,
            .dosage-label,
            .title {
                text-transform: uppercase;
                color: #C4FFF9;
                //font-size: 0.9em;
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
                font-weight: 400;
            }

            .accordion-list {
                margin-top: 0.5em;
            }

            .repetition-container {
                display: flex;
                flex-direction: row;
                padding-top: 0.5em;
                margin-bottom: 0.8em;

                .radio-button {
                    -webkit-appearance: none;
                    background-color: #0D4944;
                    box-shadow: $dark-button-box-shadow;
                    -webkit-box-shadow: $dark-button-box-shadow;
                }
            }

            .occurrence-container {
                display: grid;
                padding-top: 0.5em;
                grid-template-columns: repeat(auto-fit, 10em);

                .Checkbox-Container {
                    margin-bottom: 6%;

                    .Checkbox-View {
                        -webkit-appearance: none;
                        background-color: #114A45;
                        box-shadow: $dark-button-box-shadow;
                        -webkit-box-shadow: $dark-button-box-shadow;
                    }

                    .Checkbox-Title {
                        margin-inline-start: 6px;
                    }
                }
            }

            .occurrence-input-container {
                position: relative;

                .occurrence-error {
                    color: #F48585;
                    margin-bottom: 1.5em;
                }

                form {
                    width: 70%;

                    .form-control {
                        text-indent: 45px;
                    }
                }

                form .input-group {
                    margin-bottom: 10px !important;
                }

                .prefix {
                    display: none;
                    position: absolute;
                    top: 10px;
                    left: 0
                }

                .suffix {
                    display: none;
                    position: absolute;
                    top: 10px;
                    left: 58%;
                    color: #46AAA0;
                    opacity: 0.16;
                }

                form:focus-within~span {
                    display: inline-block !important;
                }

            }
        }
    }

    .reminder-filters-container {
        background-color: #0A2B2766;
        width: 95%;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding-inline: 1em;
        padding-block: 2em;

        .mb-4,
        .text-area {
            margin-bottom: 1.5em !important;
        }

        .message-error {
            color: #F48585;
            margin-bottom: 1.5em;
        }

        .email-container {
            .Checkbox-Container {
                .Checkbox-View {
                    -webkit-appearance: none;
                    background-color: #114A45;
                    box-shadow: $dark-button-box-shadow;
                    -webkit-box-shadow: $dark-button-box-shadow;
                }
            }

            .multiple-input-form .multiple-input-group {
                margin-bottom: 10px !important;
            }

            .error {
                color: #F48585;
            }
        }

        .users-selector {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: fit-content;
            -webkit-appearance: none !important;
            box-shadow: $dark-button-box-shadow !important;
            -webkit-box-shadow: $dark-button-box-shadow !important;
            border-radius: 5px;
            height: 2.5em;
            position: relative;
            margin-bottom: 2em;

            .triangle {
                position: absolute;
                width: 0;
                height: 0;
                top: 2.7em;
                left: 5.8em;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 12px solid $light-container-background;
                z-index: 3000 !important;
            }

            .users-list-container {
                position: absolute;
                width: 13em;
                height: 15em;
                top: 3.5em;
                margin-bottom: 1em;
                z-index: 6000;

                .Checkbox-Container {
                    .Checkbox-View {
                        -webkit-appearance: none;
                        background-color: #196A63;
                        box-shadow: 3px 3px 6px #02312C64;
                        -webkit-box-shadow: 3px 3px 6px #02312C64;
                    }
                }

                ul {
                    border-radius: 5px;
                    overflow-y: scroll;
                    width: 100%;
                    height: 100%;
                    list-style: none;
                    background-color: #196A63;
                    padding-inline-start: 1em;
                    padding-block: 1em;

                    li {
                        margin-bottom: 0.6em;
                    }
                }
            }

            button {
                display: flex;
                outline: none;
                border-color: transparent;
                background-color: #0D4944;
                align-items: center;
                color: #73E4CC;
            }

            .users-button {
                display: flex;
                flex: 4;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                height: 100%;

                span {
                    margin-inline-start: 0.7em;
                    white-space: nowrap;
                }
            }

            .clear-button {
                height: 80%;
                display: flex;
                flex: 1;
                justify-content: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                position: relative;
                border-left: 1px solid #46AAA02C;
            }
        }

        .selection-key {
            //color: #C4FFF9;
            //font-weight: 400;
            //font-size: 0.9em;
            @extend .title;
            margin-bottom: 1em;
        }

        .selected-date-button,
        .selected-time-button {
            border: none;
            outline: none;
            background-color: #0D4944;
            -webkit-appearance: none;
            box-shadow: $dark-button-box-shadow;
            -webkit-box-shadow: $dark-button-box-shadow;
            width: 30%;
            height: 2.5em;
            border-radius: 5px;
            margin-bottom: 1.5em;
        }

        .Timer-Container .calendar-button {
            display: flex;
            align-items: center;
            border: none;
            outline: none;
            background-color: #0D4944;
            -webkit-appearance: none;
            box-shadow: $dark-button-box-shadow;
            -webkit-box-shadow: $dark-button-box-shadow;

            // width:30%;
            // height: 2.5em;
            // border-radius: 5px;
            // margin-bottom: 1.5em;
            span {
                padding-inline-start: 0.5em;
                padding-bottom: 0.3em;
            }
        }

        .text-area {

            //  margin-top: 1.5em;
            .input-group-prepend {
                top: 50% !important;
            }
        }
    }

    .btn {
        align-self: center;
        margin-block-start: 5%;
        // position: absolute;
        // bottom: 2em
    }
}

[dir='rtl'] .new-event-filters {
    .prefix {
        left: inherit !important;
        right: 0
    }

    .suffix {
        left: 10em !important;
    }

    .date-duration-button::before {
        right: 0.3em;
        left: inherit !important
    }

    .triangle {
        right: 30px;
        left: inherit !important
    }

    .calendar {
        right: 0.2em;
        left: inherit !important
    }
}

.failure-container {
    background: #A54747 0% 0% no-repeat padding-box !important;
    color: #FFC6C6 !important;
}

.modal-body-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .cancel-event-header {
        text-transform: uppercase;
        color: #C4FFF9;
        margin-top: 1em;
        margin-bottom: 0.3em;
        font-size: calc(12px + (14 - 10) * ((100vw - 320px) / (2800 - 320)));
    }

    .cancel-event-subheader {
        color: #46AAA0;
        margin-bottom: 3em;
        font-size: calc(12px + (14 - 10) * ((100vw - 320px) / (2800 - 320)));
        max-width: 90%;
        text-align: center;
    }

    label {
        font-size: 1em;
        letter-spacing: 0.18px;
    }
}

// .create-event-title {
//     //color: #C4FFF9;
//     padding-top: 2em;
//     //font-size: 1em;
//     @extend .title;
//     padding-inline-start: 2em;

//     form .input-group {
//         margin-top: 30px !important;
//         margin-bottom: 0 !important;
//     }

//     .title-error {
//         color: #F48585;
//         margin-inline-start: 1.25em;
//     }

//     // text-transform: uppercase;
// }

.message-container {
    display: flex;
    flex-direction: row;
    position: fixed;
    align-items: center;
    inset-inline-end: 20px;
    bottom: 20px;
    z-index: 2000;
    background: #D6FFE3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: #165F59;
    width: 20%;
    height: 10%;

    @media (max-width:857px) {
        min-width: 40%;
        max-width: 85%;
        height: auto;
    }

    .message-icon {
        margin-inline-start: 3%;

        svg {
            width: 70%;
            height: 70%;
            display: flex;
            flex-wrap: wrap;
        }
    }

    .message-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;

        @media (max-width:857px) {
            padding-block: 8px;
            padding-inline: 0px;
        }

        button {
            position: absolute;
            background-color: transparent;
            outline: none;
            border: none;
            display: flex;
            inset-inline-end: 2px;
            top: 0;
            width: 12%;
            height: 12%;
            min-width: 31px;
            min-height: 31px;
            flex-wrap: wrap;
        }

        .message-title {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 0.9em;
        }

        .message-subtitle {
            letter-spacing: 0px;
            font-size: 0.9em;
            font-weight: 400;
        }
    }
}


@media (min-width: 1600px) and (max-width:1900px) {
    .multiview-calendar-card {
        height: 50em !important
    }
}

@media (min-width: 1900px) and (max-width:2600px) {
    .multiview-calendar-card {
        height: 54em !important
    }
}

@media (min-width: 2600px) and (max-width:3000px) {
    .multiview-calendar-card {
        height: 58em !important
    }

    .calendar-schedule-card {

        .date-picker-container {
            width: 23em !important;
        }
    }
}

@media (min-width:3000px) {
    .multiview-calendar-card {
        height: 68em !important
    }

    .calendar-schedule-card {

        .date-picker-container {
            width: 23em !important;
        }
    }
}

[dir='rtl'] {
    .event-types-container .card-with-icon-container button {
        font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (2800 - 320)));
        font-weight: 600;
    }

    form .form-control+span {
        padding-inline-start: 10px;

        @media (max-width:424px) {
            padding-inline-start: 19px;
        }
    }

    .new-event-filters .reminder-filters-container {

        span,
        .date-duration-container span.date-duration-label {
            font-weight: 400;
        }
    }
}