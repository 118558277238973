.calendar-button-container {
  font-size: 13px;
  .calendar-button {
    background: #003a35 0% 0% no-repeat padding-box;
    box-shadow: -3px -3px 6px #00544c, 3px 4px 8px #002421;
    border-radius: 5px;
    outline: none !important;
    color: #73e4cc;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 12px;
    border: none;

    @media (hover: hover) {
      &:hover {
        background: #73e4cc;
        border: 1px solid #c4fff9;
        color: #0A645C !important;
      }
    }
  }

  .error {
    color: $error-message !important;
  }

  .calendar-label {
    margin-bottom: 8px !important;
    color: $primary-txt;
    text-transform: capitalize;
  }

  .calendar-holder {
    position: relative;
    .triangle {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 10%;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid $light-container-background;
      z-index: 1000 !important;

      @media (max-width: 768px) {
        left: 15%;
      }

      [dir="rtl"] & {
        right: 10%;
        @media (max-width: 768px) {
          right: 15%;
        }
      }
    }

    .calendar {
      position: absolute;
      top: 12px;
      z-index: 100 !important;

      .date-picker-container {
        width: 17em;
        background-color: $light-container-background !important;
      }
    }
  }

  .error-msg {
    @extend .error;
    margin-top: 8px;
    font-size: 13px;
  }
}
