#action-btn {
  border-radius: 3px;
//  background: #03403a;
  color: #73E4CC !important;
  box-shadow: -2px -2px 5px #0B837780,  4px 4px 10px #022824 !important;

  @media (hover: hover) {
    &:hover {
      background: #73e4cc !important;
      border: 1px solid #c4fff9;
      color: #0A645C !important;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
