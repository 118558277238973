.unsubmitted-plot-wrapper {
    margin-bottom: 45px;

    .card-wrapper {
        margin-bottom: 10px;

        .plot-card-title {
            display: flex;
            align-items: center;
            box-shadow: 0px 3px 6px #00312C9E;
            border: 1px solid #37998447;
            font-size: 0.7rem;
            text-transform: uppercase;
            padding-block: 7px;
            padding-inline-start: 15px;
            color: #72E3CB;

            .delete-button {
                margin-inline-start: auto;
                margin-inline-end: 15px;
                // display: flex;
                // justify-content: center;
                // align-items: center;
            }

        }

        .plot-card-body {
            background: #003A35 0% 0% no-repeat padding-box;
            border: 1px solid #73E4CC40;
            padding-inline: 1rem;
            padding-block-end: 5px;

            form .input-group .form-control+span {
                inset-inline-start: 35px !important;
            }

            .form-control {
                font-size: 0.8rem;

                form .input-group span {
                    font-size: 0.8rem;
                }

            }

            .Checkbox-Container .Checkbox-View {
                background: #003A35 0% 0% no-repeat padding-box;
                box-shadow: 3px 3px 6px #0115138A;
            }

            // .another-languages-container {
            //     margin-block-end: 10px;

            //     .language-options-view {
            //         background-color: #14514CD8;
            //         border-radius: 5px;
            //         padding-block: 1rem;
            //         padding-inline: 1rem;
            //         margin-block-start: 8px;

            //         .note {
            //             color: #46AAA0;
            //             display: flex;
            //             align-items: center;

            //             svg {
            //                 padding-inline-end: 5px;
            //             }
            //         }

            //         .language-row {
            //             display: flex;
            //             flex-direction: row;
            //             align-items: center;
            //             margin-block: -10px;

            //             form {
            //                 flex: 1;
            //             }

            //             form .input-group .form-control+span {
            //                 inset-inline-start: 35px !important;
            //             }

            //             .drop-down-selector-container {
            //                 // @extend .neuo;
            //                 padding-inline: 10px;
            //                 margin-inline-end: 15px;
            //                 background: #08443F;
            //                 margin-block-end: -10px;
            //                 box-shadow: -3px -3px 6px lighten(#08443F, 5%), 3px 4px 8px darken(#08443F, 4.3%) !important;
            //             }

            //             .delete-button {
            //                 // @extend .neuo;
            //                 display: flex;
            //                 justify-content: center;
            //                 align-items: center;
            //                 background: #08443F 0% 0% no-repeat padding-box;
            //                 box-shadow: -3px -3px 6px lighten(#08443F, 5%), 3px 4px 8px darken(#08443F, 4.3%) !important;
            //                 margin-inline: 10px;
            //                 margin-block-end: -10px;
            //             }
            //         }
            //     }
            // }

            .info-key {
                color: #C4FFF9;
                text-transform: uppercase;
                font-size: 0.8rem;
            }

            .info-value {
                color: #46AAA0;
                text-transform: capitalize;
                font-size: 0.8rem;
                margin-bottom: 10px;
                position: relative;
            }

            .select-container {
                display: flex;
                margin-top: 5px;

                .radio-button-label {
                    font-size: calc(13px + (12 - 12) * ((100vw - 320px) / (2800 - 320))) !important;
                }
            }

            .soil,
            .crop-select {
                .drop-down-selector-container {
                    background: #003A35 0% 0% no-repeat padding-box !important;
                    // @extend .neuo;
                    width: 8rem;
                    padding-block: 15px;
                    margin-block-start: 10px;
                    box-shadow: -3px -3px 6px lighten(#003A35, 5%), 3px 4px 8px darken(#003A35, 4.3%) !important;

                    .current-choice {
                        white-space: nowrap;
                        color: #73E4CC !important;
                    }

                    .choices-list {
                        width: inherit !important;
                        box-shadow: 0px 3px 6px #02312c64 !important;

                        li:not(.choice-on-focus) {
                            color: #73E4CC;
                        }
                    }

                    &:hover {
                        background: #73E4CC 0% 0% no-repeat padding-box !important;
                        border: 2px solid #C4FFF9;
                        color: #165F59;

                        .current-choice {
                            color: #165F59 !important;
                        }
                    }
                }

                .expanded {
                    background: #73E4CC 0% 0% no-repeat padding-box !important;
                    border: 2px solid #C4FFF9 !important;
                    color: #165F59 !important;

                    .current-choice {
                        color: #165F59 !important;
                    }
                }
            }

            .soil {
                .choices-list {
                    width: 150px !important;
                    height: 190px !important;
                    overflow-y: scroll !important;
                }
            }

        }
    }

    .submit-button,
    .delete-button {
        padding-inline: 0.4rem;
        min-width: 1.6rem;
        min-height: 1.6rem;
        float: right;
        border: none;
        border-radius: 5px;
        outline: none;
        // @extend .neuo;
        background: #165F59 0% 0% no-repeat;
        // box-shadow: -3px 0px 6px #0000001F;
        box-shadow: -3px -3px 6px lighten(#165F59, 5%), 3px 4px 8px darken(#165F59, 4.3%) !important;

    }

    .submit-button {
        padding-block: 0.2rem;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        .icon {
            display: inline-block;
            vertical-align: middle;
        }

        &:not(:disabled) {
            background: #73E4CC 0% 0% no-repeat;
            border: 2px solid #C4FFF9;

            svg {
                path {
                    stroke: #0A4A45;
                }
            }

            &:hover {
                background: #19B392 0% 0% no-repeat;
                border: 2px solid #C4FFF9;

                svg {
                    path {
                        stroke: #c4fff9;
                    }
                }
            }
        }

        &:disabled {
            opacity: 0.3;
        }
    }

    .delete-button {
        padding-block: 0.3rem;
        .icon {
            display: inline-block;
            vertical-align: middle;
        }

        svg {
            path {
                fill: #fd5757;
            }

            rect {
                fill: #065a53;
            }
        }

        &:hover {
            background: #73E4CC 0% 0% no-repeat padding-box !important;
            border: 2px solid #C4FFF9 !important;

            svg {
                rect {
                    fill: #73e4cc
                }
            }
        }
    }
}

[dir=rtl] .unsubmitted-plot-wrapper {
    .submit-button {
        float: left;
    }
}