.calendar-dashboard{
    .col-3, .col-9{
        padding-right: 6px !important;
        padding-left: 6px !important;
    }   
}

@media (max-width: 878px){
    .calendar-dashboard{
        // .col-12{
        //     padding-right: 7px !important;
        //     padding-left: 7px !important;
        // }   
    }
}