@charset "utf-8";
.farm-registration-wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;

  .another-languages-container {
    margin-block-end: 10px;

    .delete-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.6rem;
      height: 1.6rem;
      float: right;
      border: none;
      border-radius: 5px;
      outline: none;
      // @extend .neuo;
      background: #08443f 0% 0% no-repeat padding-box;
      margin-inline: 10px;
      margin-block-end: -10px;
      background: #08443f 0% 0% no-repeat padding-box;
      box-shadow: -3px -3px 6px lighten(#08443f, 5%),
        3px 4px 8px darken(#08443f, 4.3%) !important;

      &:hover {
        background: #73e4cc 0% 0% no-repeat padding-box !important;
        border: 2px solid #c4fff9 !important;

        svg {
          path {
            fill: #003a35;
          }

          rect {
            fill: #73e4cc;
          }
        }
      }
    }

    .language-options-view {
      background-color: #14514cd8;
      border-radius: 5px;
      padding-block: 1rem;
      padding-inline: 1rem;
      margin-block-start: 8px;

      .note {
        color: #46aaa0;
        display: flex;
        align-items: center;

        svg {
          padding-inline-end: 5px;
        }
      }

      .language-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-block: -10px;

        form {
          flex: 1;
        }

        form .input-group .form-control + span {
          inset-inline-start: 35px !important;
        }

        .drop-down-selector-container {
          // @extend .neuo;
          padding-inline: 10px;
          margin-inline-end: 15px;
          background: #08443f;
          margin-block-end: -10px;
          box-shadow: -3px -3px 6px lighten(#08443f, 5%),
            3px 4px 8px darken(#08443f, 4.3%) !important;
        }
      }
    }
  }

  .modal-body-container {
    .cancel-icon {
      width: 10rem;
      height: 10rem;
    }

    .cancel-header {
      color: #c4fff9;
      text-transform: uppercase;
      font-size: 0.9rem;
    }

    .cancel-subheader {
      color: #46aaa0;
      font-size: 0.9rem;
      padding-inline: 5rem;
      text-align: center;
    }
  }

  .farm-registration-map-container {
    z-index: 100;
    width: 72vw;
    height: 100vh;
    position: relative;
    .map-loader {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: #02312ca6 0% 0% no-repeat padding-box;
      z-index: 1000;
      .Loading {
        height: 100% !important;
      }
    }
    .mapboxgl-ctrl-top-left {
      top: 30%;

      .mapboxgl-ctrl-group {
        overflow: visible;
        box-shadow: none;
        background: transparent;

        &:before {
          content: "Zooming Tools";
          display: flex;
          justify-content: center;
          align-items: center;
          padding-inline: 5px;
          background-color: #165f5999;
          color: #f2fffe;
          font-size: 0.8rem;
          writing-mode: vertical-lr;
          transform: rotate(180deg) translate(2rem, -5.3rem);
        }

        .mapbox-gl-draw_ctrl-draw-btn,
        .mapboxgl-ctrl-compass {
          display: none;
        }

        &:first-child {
          position: absolute;
          inset-inline-start: 2rem;

          .mapboxgl-ctrl-zoom-in,
          .mapboxgl-ctrl-zoom-out {
            width: 2rem;
            height: 2rem;
            margin-bottom: 10px;
            background: #03403a 0% 0% no-repeat padding-box;
            border-radius: 5px;
            border: none;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
          }

          .mapboxgl-ctrl-zoom-in:before {
            content: "+";
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.7rem;
            color: #73e4cc;
          }

          .mapboxgl-ctrl-zoom-out:before {
            content: "−";
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.7rem;
            color: #73e4cc;
          }
        }
      }
    }

    .mapboxgl-ctrl-top-right {
      top: 40%;

      .mapboxgl-ctrl-group {
        overflow: visible;
        box-shadow: none;
        background: transparent;

        // &:after {
        //     content: "Drawing Tools";
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     padding-inline: 5px;
        //     background-color: #165F5999;
        //     color: #F2FFFE;
        //     font-size: 0.8rem;
        //     white-space: nowrap;
        //     // writing-mode: vertical-lr;
        //     transform: rotate(90deg) translate(-5rem, -0.5rem);
        // }

        .mapbox-gl-draw_ctrl-draw-btn,
        .mapboxgl-ctrl-compass {
          display: none;
        }
      }
    }

    .shapes-buttons-container {
      position: absolute;
      display: flex;
      inset-inline-end: -1.5rem; // top: 50%;
      flex-direction: column;
      visibility: hidden;
      &:after {
        content: "Drawing Tools";
        display: flex;
        justify-content: center;
        align-items: center;
        padding-inline: 5px;
        background-color: #165f5999;
        color: #f2fffe;
        font-size: 0.8rem;
        white-space: nowrap;
        // writing-mode: vertical-lr;
        transform: rotate(90deg) translate(-5rem, -0.5rem);
      }

      button {
        width: 2rem;
        height: 2rem;
        margin-bottom: 10px;
        background: #03403a 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .triangle,
        .animation-tooltip {
          display: none;
        }

        svg {
          width: 1rem;
          height: 1rem;
        }
        .close-icon svg {
          width: inherit;
          height: inherit;
        }

        &:disabled {
          opacity: 0.4;
        }
      }
      .selected:not(:disabled) {
        @media (min-width: 1025px) {
          background: #73e4cc 0% 0% no-repeat padding-box;
          border: 2px solid #c4fff9;
          #circularDef,
          #RectangularDef {
            fill: #165f59;
          }
          #Path_3936,
          #Ellipse_1321,
          #Ellipse_1325,
          #Ellipse_1322,
          #Ellipse_1323,
          #Ellipse_1324 {
            fill: #165f59;
            stroke: #73e4cc;
          }
        }
      }

      button:not(:disabled):hover {
        @extend .selected;
        @media (min-width: 1025px) {
          .triangle,
          .animation-tooltip {
            display: block;
          }
          .triangle {
            position: absolute;
            top: 0.3rem;
            inset-inline-start: -0.9rem;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #c4fff9;
          }
          .animation-tooltip {
            position: absolute;
            width: 12rem;
            inset-inline-start: -12.9rem;
            background-color: #156e67;
            border-radius: 5px;
            border: 1px solid #c4fff9;

            .text {
              background-color: #165f59;
              font-size: 0.8em;
              color: #c4fff9;
              padding: 10px;
              text-align: start;
              .proceed-button {
                display: none;
              }
            }
            .animation-tooltip .close-icon {
              display: none;
            }
          }
          &:before {
            content: "";
            top: 0.4rem;
            inset-inline-start: -0.87rem;
            position: absolute;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid #165f59;
            z-index: 1002;
          }
        }
      }
      @media (max-width: 1024px) {
        .show-animation {
          background: #73e4cc 0% 0% no-repeat padding-box;
          border: 2px solid #c4fff9;
          #circularDef,
          #RectangularDef {
            fill: #165f59;
          }
          #Path_3936,
          #Ellipse_1321,
          #Ellipse_1325,
          #Ellipse_1322,
          #Ellipse_1323,
          #Ellipse_1324 {
            fill: #165f59;
            stroke: #73e4cc;
          }
          .close-icon {
            padding-block-start: 10px;
            padding-inline-end: 10px;
            text-align: end;
            border-radius: 50%;
          }
          .triangle,
          .animation-tooltip,
          .animation-tooltip .close-icon {
            display: block;
          }
          .triangle {
            position: absolute;
            top: 0.3rem;
            inset-inline-start: -0.9rem;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #c4fff9;
          }
          .animation-tooltip {
            position: absolute;
            width: 12rem;
            inset-inline-start: -12.9rem;
            background-color: #156e67;
            border-radius: 5px;
            border: 1px solid #c4fff9;
            .text {
              background-color: #165f59;
              font-size: 0.8em;
              color: #c4fff9;
              padding: 10px;
              text-align: start;
              .proceed-button {
                display: block;
                background-color: #73e4cc;
                border: none;
                outline: none;
                text-align: center;
                width: fit-content;
                max-width: 90%;
                padding: 5px;
                margin: auto;
                .proceed-icon {
                  svg {
                    width: inherit;
                    height: inherit;
                  }
                }
              }
            }
          }
          &:before {
            content: "";
            top: 0.4rem;
            inset-inline-start: -0.87rem;
            position: absolute;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid #165f59;
            z-index: 1002;
          }
        }
        .hide-animation {
          .triangle,
          .animation-tooltip,
          &:before {
            display: none;
          }
        }
      }
    }
    .contact-us-wrapper {
      position: absolute;
      inset-inline-end: 2.6rem;
      inset-block-end: 2rem;
      @media (max-width: 850px) {
        inset-inline-end: 2.7rem;
      }
      & .contact-us {
        background: $main_layer 0% 0% no-repeat padding-box;
      }
    }
    .badge-wrapper {
      position: absolute;
      inset-inline-end: 2rem;
      top: 2rem;
    }
    .coordinates {
      .suggestions {
        width: 283px;
      }
    }

    .wgs {
      .suggestions {
        width: 341px;
      }
    }

    .search-wrapper {
      display: flex;
      flex-direction: row;
      margin-block-start: 2rem;
      margin-inline-start: 2rem;

      .selector-component-wrapper {
        margin-inline-end: 15px;

        .selector-list-item {
          .title {
            color: #165f59;
            font-family: nunito_bold;
            text-transform: capitalize;
          }

          .subtitle {
            color: #165f5999;
            font-family: nunito_semibold;
          }
        }
      }

      .wgs-wrapper,
      .coordinates-wrapper {
        display: flex;
        flex-direction: row;
        background-color: white;
        height: 45px;
        align-items: flex-end;
        padding-block-end: 7px;
        z-index: 1000;
        padding-inline: 10px;
        font-size: 0.8em;
        border: 2px solid #165f59;
        border-radius: 5px;

        .input-suffix {
          top: 0;
        }

        .loop-icon {
          svg {
            path {
              fill: #07403a;
            }
          }
        }

        form {
          margin-inline-start: 12px;
          width: 35px;

          .input-group,
          .mb-4 {
            margin: 0;
            margin-bottom: 0 !important;

            .form-control {
              color: #165f59 !important;
              border-bottom-color: #46aaa0;
            }

            input {
              height: 20px;
            }

            span {
              color: #46aaa0;
            }
          }
        }
      }

      .coordinates-wrapper.hide {
        display: none;
      }

      .wgs-wrapper.show,
      .coordinates-wrapper.show {
        display: flex;
        margin-inline-start: -240px;
      }

      .coordinates-wrapper.show {
        form {
          width: 100px;
        }
      }

      .wgs-wrapper.hide {
        display: none;
      }

      .geocoder.show {
        display: block;
      }

      .geocoder.hide {
        input {
          visibility: hidden;
        }
      }

      .mapboxgl-ctrl-geocoder {
        // margin-block-start: 2rem;
        // margin-inline-start: 2rem;
        display: flex;
        .mapboxgl-ctrl-geocoder--icon {
          fill: #07403a;
          top: 13px !important;
        }

        .mapboxgl-ctrl-geocoder--powered-by {
          display: none !important;
        }

        .mapboxgl-ctrl-geocoder--input {
          border-radius: 3px;
          border: 2px solid #165f59;
          color: #07403a;
          font-weight: 500;
          font-size: 0.8em;
          height: 45px;
          background: #ffffff 0% 0% no-repeat padding-box;
          font-family: nunito_bold;

          &::placeholder {
            color: #07403a;
            font-weight: 500;
          }
        }

        .suggestions {
          top: 100% !important;
          background: #ffffffe6 0% 0% no-repeat padding-box !important;
          border: 2px solid #165f59 !important;
          border-radius: 0px 0px 5px 5px !important;

          .mapboxgl-ctrl-geocoder--suggestion {
            padding-top: 10px !important;
            border-top: 1px solid #07403a4d !important;
          }

          li:first-child {
            .mapboxgl-ctrl-geocoder--suggestion {
              border-top: 0 none !important;
            }
          }
        }

        .mapboxgl-ctrl-geocoder--suggestion-title {
          color: #07403a;
          display: flex;
          flex-direction: row;

          &::before {
            content: url("../../assets/icons/svg/constants/loc.svg");
            padding-inline-end: 5px;
            transform: scale(0.8);
          }
        }

        .mapboxgl-ctrl-geocoder--suggestion-address {
          color: #167a6f;
        }
      }
    }
    .layer-navigation {
      position: absolute;
      inset-inline-start: 2rem;
      inset-block-end: 2rem;
      $dropdown-width: 8.8rem;
      .choices-list {
        top: -5.8rem !important;
      }
      .current-choice-text {
        margin-inline-start: 6px;
      }
      .drop-down-selector-container {
        width: $dropdown-width;
        border: solid 2px transparent;
        padding-inline: 0.6rem;
        box-shadow: none !important;
        border-radius: 5px;
        padding-block: 1rem;
        & .current-choice {
          color: $primary;
          svg path {
            fill: $primary;
          }
        }
        .triangle {
          top: -1.2rem;
          border-top: 10px solid $sidebar;
          border-bottom: 0;
        }
        & .choices-list {
          width: 23em;
          top: -13.6rem !important;
          padding-top: 0.5rem;
          background-color: rgba(22, 95, 89, 0.95);
          color: $unselected-text;
          z-index: 100;
        }
        &.text-hovered {
          .dropdown-content {
            color: $cta-txt;
          }
          border: solid 2px $primary-txt;
          box-shadow: none !important;
          background-color: $primary;
          svg path {
            fill: $cta-txt;
          }
        }
      }
      .choice-content-wrapper {
        margin-top: 3px;
        margin-bottom: 1rem;
        opacity: 0.8;
      }
      .aside-tooltip {
        position: relative;
        display: inline-block;
        .aside-tooltip-wrapper {
          position: absolute;
          inset-inline-start: 8.6rem;
          top: 9px;
          display: flex;
          .aside-tooltiptext {
            width: 8rem;
            @include rgba-color(
              "background-color",
              $dark-button-background,
              0.9
            );
            color: $primary-txt-with-opactiy;
            text-align: center;
            padding: 5px 5px;
            border-radius: 6px;
            font-size: 0.8em;
            font-family: nunito_regular;
          }
          .aside-triangle {
            width: 15px;
            height: 15px;
            @include rgba-color(
              "background-color",
              $dark-button-background,
              0.9
            );
            clip-path: polygon(50% 50%, 100% 0%, 100% 100%);
            margin-top: 5px;
            margin-inline-end: -1px;
          }
        }
      }
      .top-tooltip {
        position: relative;
        display: inline-block;
        .top-tooltip-wrapper {
          position: absolute;
          display: flex;
          inset-inline-start: -1.2rem;
          top: -7.2rem;
          z-index: 99;
          opacity: 0;
          animation: showTooltip 3s ease-in-out, hideTooltip 20s ease-in-out 1s;
          .top-tooltiptext {
            width: 12.6rem;
            @include rgba-color("background-color", $light-bg, 0.9);
            color: $text-to-light-bg;
            text-align: center;
            padding: 5px 5px;
            border-radius: 6px;
            font-size: 0.9em;
            font-family: nunito_regular;
            padding: 0.9rem;
          }
          .top-triangle {
            width: 12px;
            height: 12px;
            @include rgba-color("background-color", $light-bg, 0.9);
            clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
            position: relative;
            top: 6.5rem;
            inset-inline-start: 5rem;
          }
        }
        @keyframes hideTooltip {
          0% {
            opacity: 1;
            z-index: 102;
          }
          90% {
            opacity: 0.8;
            z-index: 102;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes showTooltip {
          from {
            top: -10rem;
          }
          to {
            top: -7.2rem;
          }
        }
      }
    }
  }

  .farm-registration-panel-container,
  .farm-registration-panel-container .Panel {
    width: 28vw;
    height: 100vh;
    z-index: 10;

    ::-webkit-scrollbar {
      height: 100% !important;
      width: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #256f68;
      border-radius: 10px;
      width: 2px !important;
      cursor: pointer !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #348c83;
    }

    ::-webkit-scrollbar {
      display: block !important;
    }
  }

  .farm-registration-panel-container {
    position: relative;

    .Panel-Header {
      align-items: center;
      color: #c4fff9;
      text-transform: uppercase;
      padding-inline-start: 1.5rem;
      font-size: 0.8rem;
    }

    .panel-subtitle {
      padding-inline-start: 1.5rem;
      padding-inline-end: 2rem;
      font-size: 0.8rem;
      color: #46aaa0;
      margin-bottom: 1rem;
    }

    .proceed-button {
      width: 40%;
      margin-inline: auto;
    }

    .panel-content-wrapper {
      height: 75vh;
      overflow-y: scroll;
      padding-block: 5px;

      .soil-select {
        .drop-down-selector-container {
          background-color: #145853;
          // @extend .neuo;
          box-shadow: -3px -3px 6px lighten(#145853, 5%),
            3px 4px 8px darken(#145853, 4.3%) !important;
          width: 8rem;
          padding-block: 15px;
          margin-block-start: 10px;

          .triangle {
            border-bottom: 10px solid #10524d !important;
            top: 2.5em !important;
          }

          .current-choice {
            white-space: nowrap;
            color: #73e4cc !important;
          }

          .choices-list {
            background-color: #10524d;
            box-shadow: 1px 3px 8px #00000026;
            z-index: 10 !important;
            top: 3.2em !important;
            width: 150px !important;
            height: 190px !important;
            overflow-y: scroll !important;

            li:not(.choice-on-focus) {
              color: #73e4cc;
            }
          }

          &:hover {
            background: #73e4cc 0% 0% no-repeat padding-box;
            border: 2px solid #c4fff9;
            color: #165f59;

            .current-choice {
              color: #165f59 !important;
            }
          }
        }

        .expanded {
          background: #73e4cc 0% 0% no-repeat padding-box !important;
          border: 2px solid #c4fff9 !important;
          color: #165f59 !important;

          .current-choice {
            color: #165f59 !important;
          }
        }
      }

      .basic-info-wrapper {
        padding-inline: 1.5rem;
        margin-top: -5px;

        .edit-button {
          // @extend .neuo;
          outline: none;
          border: none;
          padding-inline: 0.4rem;
          padding-block: 0.2rem;
          min-width: 1.6rem;
          min-height: 1.6rem;
          border-radius: 5px;
          background: #165f59 0% 0% no-repeat padding-box;
          box-shadow: -3px -3px 6px lighten(#165f59, 5%),
            3px 4px 8px darken(#165f59, 4.3%) !important;
          // box-shadow: -3px 0px 6px #0000001F;
          position: absolute;
          inset-inline-end: 5px;

          &:hover {
            background: #73e4cc 0% 0% no-repeat padding-box;
            border: 2px solid #c4fff9;

            svg {
              path {
                fill: #0a4a45;
              }
            }
          }
        }

        .info-key {
          color: #c4fff9;
          text-transform: uppercase;
          font-size: 0.8rem;
        }

        .location-input,
        .description-input {
          .another-languages-container {
            margin-block-start: 10px;

            .Checkbox-Container {
              .Checkbox-View {
                width: 15px;
                height: 15px;
              }

              .Checkbox-Title {
                font-size: 0.8rem;
              }
            }

            .language-options-view
              .language-row
              form
              .input-group
              .form-control
              + span {
              inset-inline-start: 0 !important;
            }
          }
        }
        .info-value {
          color: #46aaa0;
          text-transform: capitalize;
          font-size: 0.8rem;
          margin-bottom: 10px;
          position: relative;
          @extend .location-input;

          .another-languages-container {
            margin-block-start: 10px;
          }
        }

        .input-error-message.custom-in-language-toggle {
          padding-top: 1.6em !important;
          margin-bottom: -10px;
        }
        .location-input .input-group {
          margin-bottom: 0px !important;
        }
        .text-area:empty {
          margin-top: -10px;
        }

        .input-group-prepend {
          bottom: 12%;
        }

        .line-seperator {
          width: 100%;
          border-bottom: 1px solid #46aaa080;
          opacity: 0.5;
          margin-block: 20px;
        }
        .field-gap {
          margin-block: 20px;
        }
        .description-input {
          .mb-4 {
            margin-bottom: 15px !important;
          }

          .input-group-text {
            align-items: flex-end;
          }
        }

        .description-length {
          display: flex;
          justify-content: flex-end;
          font-size: 0.7rem;
          color: #46aaa0;
          margin-block-start: -10px;
        }
      }
      .dimmed-checkbox .Checkbox-Container {
        opacity: 0.3;
        pointer-events: none;
      }
      .plots-error-message {
        @include rgba-color("background-color", $remaining-bg, 0.7);
        color: $remaining-color;
        margin-inline: 1.5rem;
        border-radius: 4px;
        display: flex;
        padding-block: 0.9rem;
        padding-inline: 0.7rem;
        &__icon {
          margin-top: -3px;
        }
        &__content {
          margin-inline-start: 6px;
          &__header {
            font-size: 1rem;
            font-family: "nunito_semibold";
          }
          &__subheader {
            font-size: 1rem;
            font-family: "nunito_regular";
            margin-bottom: -0.2rem;
            margin-top: -2px;
            line-height: 1.2rem;
          }
        }
      }
      .plot-list-wrapper {
        padding-inline: 2rem;
        padding-block-start: 15px;
        padding-block-end: 25px;
        .unsubmitted-plot-wrapper {
          .plot-card-body form .input-group + .input-error-message {
            padding-top: 0 !important;
            margin-top: -10px;
            margin-bottom: 9px;
          }
          .submit-button {
            background-color: $primary;
            color: $cta-txt;
          }
        }
      }
    }
  }
}
[dir="rtl"] .farm-registration-wrapper {
  .farm-registration-map-container {
    .shapes-buttons-container {
      inset-inline-end: -3rem;
      button:not(:disabled):hover .animation-tooltip {
        @media (min-width: 1025px) {
          inset-inline-start: -13rem;
        }
      }
      @media (max-width: 1024px) {
        .show-animation {
          .triangle {
            position: absolute;
            top: 0.3rem;
            inset-inline-start: -0.9rem;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid #c4fff9 !important;
            border-left: none !important;
          }
          &:before {
            content: "";
            top: 0.4rem;
            inset-inline-start: -0.87rem;
            position: absolute;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 8px solid #165f59 !important;
            border-left: none !important;
          }
        }
      }
    }
  }
}
@media (max-width: 850px) {

  .search-wrapper {
    margin-block-start: 5rem !important;

    .default-input-focus-padding-inline {
      padding-inline-start: 0;
    }
  }

  .farm-registration-map-container .badge-wrapper {
    inset-inline-start: 2rem !important;
    inset-inline-end: inherit !important;
  }

  .wgs-wrapper.show,
  .coordinates-wrapper.show {
      margin-inline-start: -220px !important;
  }

//   .mapboxgl-ctrl-geocoder--input {
//     height: 40px !important;
//   }

  .mapboxgl-ctrl-geocoder--icon-search {
    top: 10px !important;
  }

  .mapboxgl-ctrl-geocoder {
    // margin-inline-start: 2rem !important;
    // margin-block-start: 5rem !important;
    min-width: 220px !important;
    max-width: 220px !important;
  }

  .farm-registration-wrapper {
    flex-direction: column;
  }

  .farm-registration-map-container {
    width: 100vw !important;
    height: 74% !important;
  }

  .farm-registration-panel-container,
  .farm-registration-panel-container .Panel {
    width: 100vw !important;
    height: 26% !important;
  }

  .farm-registration-panel-container .Panel-Container-View {
    position: absolute;
    height: 100%;
  }
}
[dir="rtl"] .farm-registration-wrapper {
  .farm-registration-map-container {
    .badge-wrapper .badge-container .badge-text {
      font-size: 0.7rem;
      font-weight: 600;
      font-family: "tajawal_medium";
      span {
        font-family: "tajawal_medium" !important;
      }
    }
    .mapboxgl-ctrl-geocoder {
      .mapboxgl-ctrl-geocoder--input {
        font-family: tajawal_medium;
      }
    }
    .layer-navigation {
      .drop-down-selector-container .choices-list {
        inset-inline-start: 0rem;
      }
      .top-tooltip {
        .top-tooltip-wrapper {
          .top-triangle {
            top: 6.4rem;
          }
          .top-tooltiptext {
            font-family: tajawal_regular;
          }
        }
      }
      .aside-tooltip {
        .aside-tooltip-wrapper {
          .aside-triangle {
            clip-path: polygon(0% 0%, 0% 100%, 50% 50%);
          }
          .aside-tooltiptext {
            font-family: tajawal_regular;
          }
        }
      }
    }
    .contact-us-wrapper {
      inset-inline-end: 2.5rem;
    }
  }
  .farm-registration-panel-container {
    .panel-content-wrapper {
      .plots-error-message {
        &__content {
          &__header {
            font-family: "tajawal_medium";
            font-size: 0.85rem;
            font-weight: 600;
          }
          &__subheader {
            font-family: "tajawal_regular";
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
[dir="rtl"] .farm-registration-map-container {
  .contact-us-wrapper {
    @media (max-width: 850px) {
      inset-inline-end: 2.4rem;
    }
  }
  .mapboxgl-ctrl-top-right {
    top: 40%;

    .mapboxgl-ctrl-group {
      overflow: visible;
      box-shadow: none;
      background: transparent;

      .mapbox-gl-draw_ctrl-draw-btn,
      .mapboxgl-ctrl-compass {
        display: none;
      }

      &:first-child {
        position: absolute;
        inset-inline-start: 2rem;

        &:before {
          content: "أدوات التكبير";
          display: flex;
          justify-content: center;
          align-items: center;
          padding-inline: 5px;
          background-color: #165f5999;
          color: #f2fffe;
          font-size: 0.8rem;
          white-space: nowrap;
          transform: rotate(90deg) translate(2.9rem, -3.5rem);
        }

        .mapboxgl-ctrl-zoom-in,
        .mapboxgl-ctrl-zoom-out {
          width: 2rem;
          height: 2rem;
          margin-bottom: 10px;
          background: #03403a 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .mapboxgl-ctrl-zoom-in:before {
          content: "+";
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.7rem;
          color: #73e4cc;
        }

        .mapboxgl-ctrl-zoom-out:before {
          content: "−";
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.7rem;
          color: #73e4cc;
        }
      }
    }
  }

  .mapboxgl-ctrl-top-left {
    top: 30%;

    .mapboxgl-ctrl-group {
      overflow: visible;
      box-shadow: none;
      background: transparent;

      &:before {
        content: "ادوات الرسم";
        display: flex;
        justify-content: center;
        align-items: center;
        padding-inline: 5px;
        background-color: #165f5999;
        color: #f2fffe;
        font-size: 0.8rem;
        white-space: nowrap;
        writing-mode: inherit;
        transform: rotate(-90deg) translate(-4.5rem, 1.3rem);
        margin-inline-start: 35px;
      }

      &:first-child {
        inset-inline-start: inherit;
      }

      .mapbox-gl-draw_ctrl-draw-btn,
      .mapboxgl-ctrl-compass {
        display: none;
      }
    }
  }

  .shapes-buttons-container {
    inset-inline-end: -1rem;
    @media (min-width: 1025px) {
      button:not(:disabled):hover:before {
        inset-inline-start: -1rem;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #165f59;
        border-left: none !important;
      }

      button:not(:disabled):hover {
        .animation-tooltip {
          inset-inline-start: -12.6rem;
        }

        .triangle {
          inset-inline-start: -1.01rem;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid #c4fff9;
          border-left: none !important;
        }
      }
    }
    &:after {
      display: none;
    }
  }
}
