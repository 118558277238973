.multiview-calendar-container {
    height: 100%;
    width: 100%;

    .rbc-row-content {
        z-index: initial !important;
    }

    .multiview-toolbar-container {
        background-color: $accordion-bg;
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .navigation-container {
            position: absolute;
            display: flex;
            flex-direction: row;
            left: 2%;
            color: $primary-txt;
            // min-width: 14%;

            .navigation-button {
                // display: flex;
                // width: 1.5rem;
                background-color: transparent;
                outline: none;
                border: none;
                margin-inline: auto;

                svg {
                    width: 0.8rem;
                    height: 0.8rem;
                }
            }

            .current-date {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                // width: 80%;
                // margin-inline: auto;
                padding-inline: 10px;
                font-size: 0.8rem;
            }
        }

        .buttons-wrapper {
            position: absolute;
            inset-inline-end: 10px;

            .new-event-button {
                background-color: $accordion-bg;
                box-shadow: $dark-button-box-shadow !important;
                color: $primary;
                outline: none;
                border: none;
                // position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                // right: 2%;
                height: 2rem;
                padding-inline: 10px;
                padding-block: 5px;
                font-size: 0.8rem;
            }
        }

        .irrigation-title {
            color: #FFD0A8;
        }
    }

    @mixin shadow($neuo) {
        -webkit-appearance: none !important;
        $lower-shadow: 4px 4px 10px darken($neuo, 5%);
        $upper-shadow: -4px -4px 5px lighten($neuo, 7%);
        box-shadow: $upper-shadow, $lower-shadow;
        -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
    }

    .neuo {
        border-radius: 5px;
        border-style: hidden;
        background: $main_layer;
        @include shadow($main_layer);
        cursor: pointer;
    }

    .rbc-time-view {
        border: 1.5px solid $card-with-icon-border;
        border-radius: 0px 0px 5px 5px;
        padding-bottom: 1%;
        background-color: $empty-state-graph;
    }

    .rbc-allday-cell {
        .rbc-row {
            background-color: $empty-state-graph;
        }
    }

    .rbc-timeslot-group {
        background-color: $empty-state-graph;
        border-bottom: none !important;
        color: $secondary-txt;
    }

    .rbc-time-content>*+*>* {
        border-left-color: $card-with-icon-border;
    }

    .rbc-time-content {
        border-top: none;
    }

    // .rbc-time-header-content {
    //     border-left-color: $card-with-icon-border;
    // }
    .rbc-time-header.rbc-overflowing {
        border-right: none;
    }

    .rbc-time-gutter {
        transform: translateY(-0.3em);
    }

    .rbc-day-slot .rbc-time-slot {
        border-top: 1px solid $card-with-icon-border;
    }

    .rbc-current-time-indicator {
        background-color: $primary-txt !important;
    }

    .rbc-day-slot .rbc-event-label {
        display: none;
    }

    .rbc-event {
        padding: 0;
        overflow: visible !important
    }

    .month-date-header {
        margin-block: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 12px
    }

    .rbc-month-view {
        border: 1.5px solid $card-with-icon-border;
        border-radius: 0px 0px 5px 5px;
    }

    .rbc-header {
        display: flex;
        background-color: $empty-state-graph;
        justify-content: center;
        align-items: center;
        border-bottom: none;
        min-height: 3em;
    }

    .rbc-header+.rbc-header {
        border-left: none;
    }

    .rbc-day-bg+.rbc-day-bg {
        border-left: 1px solid $calendar-border
    }

    .rbc-month-row+.rbc-month-row {
        border-top: 1px solid $calendar-border;
    }

    .rbc-event-content {
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
    }

    .rbc-month-row {
        overflow: visible !important;

        .rbc-today {
            background-color: transparent;
        }

        .rbc-off-range-bg {
            background: $off-range-event-background;
        }

        .rbc-show-more {
            display: flex !important;
            justify-content: flex-end;
            background-color: transparent !important;
            padding-inline-end: 2px;
            font-weight: 400 !important;
            font-size: 80% !important;
            color: $secondary-txt !important;
        }
    }

    .rbc-today {
        background-color: $empty-state-graph;
    }

    .rbc-day-slot .rbc-events-container {
        border-left: none !important;
    }

    .rbc-time-column .rbc-timeslot-group {
        border-left: none !important;
    }

    .rbc-time-content>.rbc-time-gutter {
        border-inline-end: 1px solid $calendar-border
    }

    .rbc-time-header-content {
        border-left: 1px solid $calendar-border
    }

    [dir='rtl'] .rbc-time-header-content {
        border-left: none !important;
        border-right: 1px solid $calendar-border !important
    }

    // .rbc-time-header-cell {
    //     margin-bottom: 1.5em;
    //     border-bottom: 0.01px solid $primary-txt45 !important;
    // }
    .rbc-date-cell {
        padding-inline-start: 5px;
        text-align: start;
    }

    .rbc-events-container {
        background-color: $events-background;
        margin-right: 0 !important;
        overflow: visible !important;
    }

    .rbc-label {
        padding: 0 25px !important;
    }

    .rbc-time-header-gutter {
        width: 87px !important;
        min-width: 87px !important;
        max-width: 87px !important;
    }



    .fertigation:hover {
        background-color: $fertigation-background;
        border: 1px solid $fertigation-border;
        border-radius: 3px;

        .event-title {
            color: $container-background !important
        }
    }

    .irrigation:hover {
        background-color: $irrigation-background;
        border: 1px solid $irrigation-border;
        border-radius: 3px;

        .event-title {
            color: $container-background !important
        }
    }

    .reminder:hover {
        background-color: $reminder-background;
        border: 1px solid $reminder-border;
        border-radius: 3px;

        .event-title {
            color: $container-background !important
        }
    }
}

.Week {
    .rbc-allday-cell {
        display: none;
    }

    .week-header-container {
        height: 100%;
        margin-block: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .date {
            border-radius: 50%;
            width: 2em;
            height: 2em;
            display: flex;
            justify-content: center;
            align-items: center
        }
    }
}

// .irrigation{
//     .rbc-time-view{
//        border: none !important
//     }
// }

[dir='rtl'] .multiview-calendar-container {
    .rbc-time-header-content {
        border-right: 1px solid #46aaa047 !important;
        border-left: none !important;
    }

    .rbc-time-header-gutter {
        width: 79px !important;
        min-width: 79px !important;
        max-width: 79px !important;
    }

    .navigation-container {
        right: 2% !important;
        left: inherit !important
    }

    .multiview-toolbar-container {
        .new-event-button {
            left: 2%;
            right: inherit !important;
        }

        .navigation-container .current-date {
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
        }
    }
}

.more-events-button {
    display: flex;
    justify-content: flex-end;
    outline: none !important;
    border: none !important;
    background-color: transparent;
    color: $primary;
}


.event-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 5px;
    position: relative;
    overflow: hidden !important;

    .event-left-border {
        height: 100%;
        width: 0.3em;
        position: absolute
    }

    .event-title {
        margin-top: 0.2em;
        color: $primary-txt;
        margin-inline-start: 1em;
        font-size: 1em
    }

    .event-details-container {
        display: flex;
        flex-direction: row
    }

    .event-status {
        position: absolute;
        bottom: 0.5em;
        left: 1em;
        font-size: 0.9em
    }

    .event-plot {
        position: absolute;
        right: 0.5em;
        bottom: 0.5em;
        font-size: 0.9em
    }
}


.popover-header {
    background-Color: $tooltip-header !important
}

.popover-body {
    background-color: $tooltip-body !important;
    border-radius: 5px !important;
}



.tooltip-content-container {
    overflow-y: visible;
    display: flex;
    flex-direction: column;
    background-color: $tooltip-body !important;
    position: relative;

    // z-index: 1000000 !important;
    .data-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.3em;

        .data-key {
            text-transform: uppercase;
            color: $cta-txt;
            font-size: 0.9em;
            font-weight: 650
        }

        .data-value {
            color: $secondary-txt;
            text-transform: capitalize;
            // z-index: 1000000 !important;
        }


        .reminder-notes,
        .reminder-recepients {
            max-width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .next-event-key {
        color: $cta-txt;
        font-size: 0.9em;
        font-weight: 650;
        margin-bottom: 0.3em
    }

    .next-event-value {
        color: $secondary-txt;
        letter-spacing: 5px;
        font-size: 2em;
        font-weight: 800;
        text-shadow: 4px 9px $secondary-txt-opacity
    }

    .plot-name {
        color: $cta-txt;
        align-self: flex-end;
        text-transform: capitalize
    }
}


.tooltip-header-container {
    display: flex;
    flex-direction: row;

    .tooltip-header-title {
        color: $primary-txt;
        font-size: 1em
    }

    .tooltip-header-status {
        color: $primary;
        font-size: 1em;
        text-transform: capitalize
    }
}


@media (max-width:800px) {

    .filter-options-container {
        position: absolute;
        inset-inline-start: -75px !important;
        top: 130%;
        z-index: 4000;
        background: #196A63 0% 0% no-repeat padding-box;
        border-radius: 5px;
        width: 150px;
        padding: 1em;

        .triangle {
            position: absolute;
            width: 0;
            height: 0;
            top: -12%;
            inset-inline-start: 75px !important;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid $light-container-background;
            z-index: 3000 !important;
        }

        .Checkbox-Container {
            margin-bottom: 7%;

            .Checkbox-View {
                background-color: #196A63;
                box-shadow: $dark-button-box-shadow;
            }

            .Checkbox-Title {
                margin-inline-start: 6px;
            }
        }
    }

    .buttons-wrapper {
        display: flex;
        flex-direction: row;

        .new-event-button {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            right: 2% !important;
            width: 1.7rem;
            height: 2rem;
            font-size: 1.3rem !important;
            // top: 22% !important;
            padding: 0 !important
        }

        .calendar-filter-button {
            background-color: $accordion-bg !important;
            box-shadow: $dark-button-box-shadow !important;
            color: $primary;
            outline: none !important;
            border: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.7rem;
            height: 2rem;
            margin-inline-end: 10px;
            position: relative;

            .filter-icon {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }
    }

    .slider-wrapper {
        // position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 100%;
        border-left: 1px solid #46AAA066;
        border-right: 1px solid #46AAA066;
    }

    .navigation-container {

        // width: 32% !important;

        .navigation-button {
            // width: 1.4rem !important;
        }

        .current-date {
            // width: 80px;
            font-size: 90%;
        }
    }
}