.Selectable .DayPicker-Day--start,
.Selectable .DayPicker-Day--end {
    border-radius: 50% !important;
    background-color: $primary !important;
    color: $cta-txt !important;
    font-weight: 400 !important;
 //   font-size: .8em !important;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    color: $cta-txt !important;
    background-color: $primary !important;
    font-weight: 400 !important;
    border-radius: 50% !important;
   // font-size: .8em !important;
    opacity: 0.3;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: $selected_days_range_bg !important;
    border-radius: 0 !important;
    color: $primary !important;
}

.DayPicker-Week {
    position: relative !important;
    z-index: 1 !important;
   // font-size: 1em !important;
}

.DayPicker-Day--outside {
    visibility: hidden;
}

.DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected::before {
    content: '';
    background-color: $selected_days_range_bg;
    width: 66%;
    height: 100%;
    display: inline-block;
    position: absolute;
    right: 50%;
    top: 0%;
    z-index: -1;
}

[dir='rtl'] .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected::before {
    content: '';
    background-color: $selected_days_range_bg;
    width: 66%;
    height: 100%;
    display: inline-block;
    position: absolute;
    right: 0%;
    top: 0%;
    z-index: -1;
}

.DayPicker-Day.DayPicker-Day--start.DayPicker-Day--end.DayPicker-Day--selected::before {
    content: '';
    background-color: $selected_days_range_bg;
    width: 0%;
    height: 100%;
    display: none;
    position: absolute;
    right: 0%;
    top: 0%;
    z-index: -1;
}

.DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected+.DayPicker-Day.DayPicker-Day--selected::before {
    content: '';
    background-color: $selected_days_range_bg;
    width: 66%;
    height: 100%;
    display: inline-block;
    position: absolute;
    left: -50%;
    top: 0%;
    z-index: -1;
}

[dir='rtl'] .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected+.DayPicker-Day.DayPicker-Day--selected::before {
    content: '';
    background-color: $selected_days_range_bg;
    width: 66%;
    height: 100%;
    display: inline-block;
    position: absolute;
    right: -50%;
    top: 0%;
    z-index: -1;
}

.DayPicker-Day--today[aria-selected=false] {
    font-weight: 600 !important;
    color: $primary !important;
    position: relative !important;

    &::after {
        content: "";
        position: absolute;
        display: inline-block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 1px solid $primary;
        border-radius: 50% !important;
    }
}

.DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--today[aria-selected=true],
.DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected.DayPicker-Day--today[aria-selected=true] {
    font-weight: 800 !important;

    &::after {
        display: none !important;
    }
}

.DayPicker-Months {
    background-color: $main_layer !important;
   // font-size: .9rem !important;
    border-radius: 4px !important;
    height: 17.5rem;
    width: 17.5rem;
}

.DayPicker-Month {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
    margin-bottom: 1em !important;
}

.DayPicker-Caption {
    text-transform: uppercase !important;
    text-align: center !important;
    color: $primary-txt !important;
   // font-size: .65rem !important;
    justify-content: space-between !important;
}

.DayPicker-Caption>div {
    font-weight: 400 !important;
   // font-size: 1.1em !important;
}

.DayPicker-NavBar {
    span {
        left: auto !important;
    }
}

.DayPicker-NavButton--prev {
    margin-right: 10.5rem !important;
    background-image: url(../../assets/icons/svg/calendar/left.svg) !important;
}

.DayPicker-NavButton--next {
    margin-right: 2rem !important;
    background-image: url(../../assets/icons/svg/calendar/right.svg) !important;
}

.DayPicker-Day {
    padding-left: 1em !important;
    padding-right: 1em !important;
    padding-top: .1em !important;
    padding-bottom: .1em !important;
    border-radius: 0% !important;
   // font-size: .8em !important;
}

.DayPicker-Body {
    padding-top: 1rem !important;
    padding-bottom: 2em !important;
    margin-top: 1rem !important;
    margin-bottom: 2em !important;
}

.DayPicker-Weekday {
    color: $secondary-txt !important;
  //  font-size: .8em !important;
}

.DayPicker-NavButton {
    right: 2rem !important;
    height: .9em !important;
    width: .9em !important;
    margin-top: -.1em !important;
}

.calendarView {
    h6 {
        @extend .gadget-title;
        margin-bottom: .2em;
    }

    p {
        @extend .gadget-subtitle;
    }
}

.fromToDates {
    div {
        display: flex;
        flex-direction: row;
        justify-content: center;

        h6 {
            @extend .gadget-title;
        }

        p {
            padding-inline-start: .5em;
            margin-top: -.1em;
            @extend .gadget-subtitle;
        }
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-inline-end: 2rem;
}

.DayPicker-Day--disabled {
    // color:transparent !important;
    opacity: 0.2 !important;
    cursor: default;
    pointer-events: none;
    /* background-color: #eff1f1; */
}


.date-picker-container {
    background-color: #03403A;
    padding: 1em;
    border-radius: 5px;

    .nice-dates-navigation_current {
        color: #C4FFF9;
     //   font-size: 14px;
    }

    .nice-dates-navigation_previous:before,
    .nice-dates-navigation_next:before {
        transform: none !important;
        // border-right: 2px solid #C4FFF9 !important;
        // border-top: 2px solid #C4FFF9 !important;
    }

    // .nice-dates-navigation_next:hover:before{

    // }
    // .nice-dates-navigation_previous:hover:before {
    // }
    .nice-dates-navigation_previous:before,
    .nice-dates-navigation_previous:hover:before {
        width: 0;
        height: 0;
        border-top: 8px solid transparent !important;
        border-bottom: 8px solid transparent !important;
        border-right: 8px solid #C4FFF9 !important;
        border-left: none !important;
    }

    .nice-dates-navigation_next:before,
    .nice-dates-navigation_next:hover:before {
        width: 0;
        height: 0;
        border-top: 8px solid transparent !important;
        border-bottom: 8px solid transparent !important;
        border-left: 8px solid #C4FFF9 !important;
        border-right: none !important;
    }

    .nice-dates-day_month {
        display: none;
    }

    .nice-dates-day:before {
        background-color: #73e4cc !important;
    }

    .nice-dates-day.-selected * {
        color: #165f59 !important;
    }

    .nice-dates-day:before:hover {
        background-color: #73e4cc !important;
    }

    .nice-dates-day.-selected:before {
        opacity: 1 !important;
    }

    .nice-dates-day_date,
    .nice-dates-week-header_day {
        color: #46aaa0;
     //   font-size: 12px;
    }

    .nice-dates-week-header {
        box-shadow: none;
        padding: 12px 0;
    }

    .nice-dates-day:not(.-disabled):not(.-today):hover:after {
        background-color: #73e4cc33;
        color: #165f59;
        border: none !important;
    }

    .nice-dates-day:not(.-disabled).-today:hover:after {
        border: none !important;
    }

    .nice-dates-day:not(.-today):after {
        border: none !important
    }

    .nice-dates-day:after {
        border: none !important;
    }

    .nice-dates-day.-selected:hover:after {
        background-color: transparent !important;
    }

    .-outside {
        visibility: hidden;
    }

    .-today:not(.-selected) {
        border: 2px solid #73e4cc;
        border-radius: 999px;
        opacity: 1;

        span {
            color: #73e4cc;
        }
    }

    .-disabled {
        opacity: 0.2;
    }

    .line-seperator {
        width: 100%;
        border-bottom: 1px solid #46AAA080;
        opacity: 0.5;
        margin-block-end: 20px;
    }

    .clear-disabled,
    .apply-disabled {
        opacity: 0.2;
    }

    .buttons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding-bottom: 0.5em;

        .month-input-container,
        .year-input-container {
            display: flex;
            flex-direction: column;
            text-transform: lowercase !important;
        }

        .input-group {
            margin-block: 0 !important;
            width: 80% !important;

            span {
                padding-inline-start: 0 !important;
                text-transform: lowercase !important;
            }
        }

        form {
            margin-inline-end: 0.2em;
        }

        button {
            background-color: transparent;
            outline: none;
            border: none;
            font-weight: 600;
            height: 2em;
            text-align: center;
        }

        .clear-dates-button {
            color: #73E4CC;
        }

        .set-dates-button {
            background: #73E4CC 0% 0% no-repeat padding-box;
            border-radius: 5px;
            color: #165F59;
            letter-spacing: 0.16px;
            padding-inline: 0.7em;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .set-dates-button::after {
            content: url('../../assets/icons/svg/calendar/proceed_EN.svg');
            // width: 60px;
            // height: 30px;
            background-repeat: no-repeat;
            padding-inline: 0.3em;
        }
    }
}

[dir='rtl'] .nice-dates-navigation_next:before {
    border-top: 8px solid transparent !important;
    border-bottom: 8px solid transparent !important;
    border-right: 8px solid #C4FFF9 !important;
    border-left: none !important;
}

[dir='rtl'] .nice-dates-navigation_next:hover:before {
    border-top: 8px solid transparent !important;
    border-bottom: 8px solid transparent !important;
    border-right: 8px solid #C4FFF9 !important;
    border-left: none !important;
}

[dir='rtl'] .set-dates-button::after {
    content: url('../../assets/icons/svg/calendar/proceed_AR.svg') !important;
}

[dir='rtl'] .nice-dates-navigation_previous:before {
    border-top: 8px solid transparent !important;
    border-bottom: 8px solid transparent !important;
    border-left: 8px solid #C4FFF9 !important;
    border-right: none !important;
}

[dir='rtl'] .nice-dates-navigation_previous:hover:before {
    border-top: 8px solid transparent !important;
    border-bottom: 8px solid transparent !important;
    border-left: 8px solid #C4FFF9 !important;
    border-right: none !important;
}

[dir='rtl'] .nice-dates-day.-selected.-selected-start:before {
    border-top-right-radius: 999px !important;
    border-bottom-right-radius: 999px !important;
    right: default !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: 0;
}

[dir='rtl'] .nice-dates-day.-selected.-selected-end:before {
    border-top-left-radius: 999px !important;
    border-bottom-left-radius: 999px !important;
    left: default !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    right: 0;
}

.specifications-container .date-picker-container {
    width: 17em;
}


@media(min-width:2000px) {

    .nice-dates-day_date,
    .nice-dates-week-header_day {
      //  font-size: 2em !important;
    }

    .date-picker-container .nice-dates-navigation_current {
       // font-size: 2em !important;
    }

    // .date-picker-container .nice-dates-navigation_next:before,
    // .date-picker-container .nice-dates-navigation_next:hover:before {
    //     border-top: 10px solid transparent !important;
    //     border-bottom: 10px solid transparent !important;
    //     border-left: 10px solid #C4FFF9 !important;
    // }

    // .date-picker-container .nice-dates-navigation_previous:before,
    // .date-picker-container .nice-dates-navigation_previous:hover:before {
    //     border-top: 10px solid transparent !important;
    //     border-bottom: 10px solid transparent !important;
    //     border-right: 10px solid #C4FFF9 !important;
    // }
}