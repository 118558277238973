.VegetationIndex_Container {
    .Export-Wrapper .modalContainer p{
        margin-block: 0 !important;
    }
    .chart-view{
        .icon-header{
             display: flex;
             justify-content: space-between;
             @media (max-width: 600px){
                flex-direction: column;
                justify-content: baseline;
                gap: 8px;
            } 
         }
        .calendar-button {
             position: relative;
             width: 230px;
             height: 35px;
             -webkit-appearance: none !important;
             border-radius: 5px;
             outline: none !important;
             color: $primary;
             background: #02312C 0% 0% no-repeat padding-box;
             box-shadow: 3px 3px 6px #001A17A3;
             -webkit-box-shadow: 3px 3px 6px #001A17A3;
             span {
                 padding-inline-start: 1.9em;
                 color: $primary;
                 font-weight: 500;
                 font-size: 0.9em;
             }
        }
        .calendar-button::before {
            content: url('../../assets/icons/svg/MainMap/calendar.svg');
            width: 2em;
            height: 1.5em;
            z-index: 1000 !important;
            position: absolute;
            inset-inline-start: 0.7em;
            top: 0.75em;
        }
        .triangle-calendar-wrapper{
            position: absolute;
            top:-51px;
            inset-inline-start: -0.5em;       
            @media(min-width:2000px) {
                .nice-dates-day_date,
                .nice-dates-week-header_day {
                    font-size: 1.1em !important;
                }
                .date-picker-container .nice-dates-navigation_current {
                    font-size: 1.1em !important;
                }
            }
            .triangle {
                position: absolute;
                width: 0;
                height: 0;
                top: 8.3em;
                inset-inline-start: 11.5em;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid $light-container-background;
                z-index: 1000 !important;
            }
            .calendar {
                position: absolute;
                inset-inline-start: 3.2em;
                top: 9.1em;
                z-index: 100 !important;
                .date-picker-container {
                    width: 20em;
                    background-color: $light-container-background !important;
                }
            }

        }
        &.card-height{
            min-height: 33em;
            display: grid;
        }
        &.card-height:has(.empty-state-graph){
            min-height: auto;
        }
        .header-buttons-container{
            display: flex;
            flex-direction: row;
            position: relative;
        }
        .single-action-button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-inline-start: 15px;
            width: 30px;
            flex-wrap: wrap;
            height: 30px;
            background: $container-background 0% 0% no-repeat padding-box;
            $lower-shadow: 4px 4px 8px darken($container-background, 3%);
            $upper-shadow: -3px -3px 6px lighten($container-background, 4%);
            -webkit-appearance: none !important;
            box-shadow: $upper-shadow, $lower-shadow !important;
            -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
            border-radius: 5px;
            border: 2px solid transparent;
            outline: none;
            @media (max-width: 800px){
            width: 27px;
            height: 27px;
        } 
        }
        .single-action-button:not(.viewer):hover {
            background-color: $primary;
            border: 2px solid $primary-txt;
            transition: 0.3s ease-out;
        }
        .single-action-button:first{
            margin-inline-start: 0;
        }
        .seasons-filter {
            #action-btn {
                height: 30px;
                width: 30px;
                background-color: #02312C;

                @media (max-width: 800px){
                    width: 27px;
                    height: 27px;
                } 

                @media (hover: hover) { 
                    &:hover {
                        background: #73e4cc;
                    }
                }  
            }

            .triangle {
                right: 9px;

                [dir="rtl"] & {
                    left: 5px;
                    right: 0;
                  }
            }

        }
        .empty-state-graph {
            background-color: inherit;
            display: flex;
            justify-content: center;
            align-items: baseline;
            flex-direction: row;
            svg{
                width: 36%;
                height: 100%;
                max-width: 400px;
                max-height: 232px;
            }
        }
        .line-graph{
            padding: 1em;
            padding-bottom: 0;
            width: 95%;  
            height: 100%;
        }
        .triangle-choice-wrapper{
            position: absolute;
            inset-block-start: 0.7em;
            inset-inline-start: 0.5em;
            .triangle{
                position: absolute;
                width: 0; 
                height: 0; 
                top: 2em;
                inset-inline-start:1em;
                border-left: 13px solid transparent;
                border-right: 13px solid transparent;
                border-bottom: 13px solid $sidebar;
                z-index: 1000 !important;
                right: 0;
                left: 40px;
                [dir="ltr"] & {
                    left: 0px;
                }
            }
            .current-choice{
                display:flex;
                justify-content:center;
                align-items:center;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
            }
            .choices-list{
                position:absolute;
                top:2.5em;
                inset-inline-start: -6em;
                width: 9em;
                background-color: $sidebar;
                color : #73E4CC;
                border-radius: 5px;
                list-style-type: none;
                padding:0;
                z-index: 1001;
                height: 200px;
                overflow-y: scroll;
                [dir="ltr"] & {
                    inset-inline-start: -10em;;
                }
                li{
                    padding-inline:1em;
                    padding-block: 0.5em;
                    position: relative;
                    .border{
                        display: none;
                    }
                }
                li:has(.border){
                    cursor: pointer;
                }
                .choice-on-focus{
                    color: $primary-txt;
                    .border{
                        display: block;
                        position: absolute;
                        inset-inline-start:0;
                        width:0.2em;
                        height: 1.7em;
                        background: $primary 0% 0% no-repeat padding-box;
                        border-radius: 0px 2px 2px 0px;
                    }
                }
            }
            .Checkbox-Container .Checkbox-View{
                background: #196A63 0% 0% no-repeat padding-box;
                box-shadow: 3px 3px 6px #02312C64;
                border-radius: 5px;
                cursor: pointer;
            }
            .choices-list.multi-select{
                width: fit-content;
                .Checkbox-Title {
                    text-wrap: nowrap;
                }
            }
        }
        .Export-Wrapper .calendar-button {
            position: relative;
            width: 10.6rem;
            height: 2.2rem;            
            background: #0D4944 0% 0% no-repeat padding-box;
            -webkit-appearance: none !important;
            box-shadow: 3px 3px 6px #032925, -0.5px -0.5px 5px #73e4cc40;
            -webkit-box-shadow: 3px 3px 6px #032925, -0.5px -0.5px 5px #73e4cc40 !important;
            border-radius: 5px;
            outline: none !important;
            color: #73E4CC;
            margin-inline-start: 10px;
        }
        .Export-Wrapper .Calendar-Wrapper .triangle{
            top: 0.7rem;
        }
        .Export-Wrapper .modalBackground .modalContainer div:has(p) p:nth-child(2){
                font-size: 0.9rem;
                color: #46AAA0;
                text-transform: none;
                margin-bottom: 1rem !important;
        }
        .graph-container {
            div:has(canvas) {
                width: 100%;
                canvas {
                    max-width: 100%;
                }
            }
        }
    }
    .table-view{
        &.card-height{
            min-height: 33em;
        }
        .card-header{
            padding-bottom:0 !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media (max-width: 600px){
                flex-direction: column;
                justify-content: baseline;
                gap: 8px;
            } 
            .table-view-header{
                min-width: 100px;
            }
            .calendar-button {
                position: relative;
                width: 230px;
                height: 35px;
                -webkit-appearance: none !important;
                border-radius: 5px;
                outline: none !important;
                color: $primary;
                background: #02312C 0% 0% no-repeat padding-box;
                box-shadow: 3px 3px 6px #001A17A3;
                -webkit-box-shadow: 3px 3px 6px #001A17A3;
                span {
                    padding-inline-start: 1.9em;
                    color: $primary;
                    font-weight: 500;
                    font-size: 0.9em;
                }
            }
           .calendar-button::before {
               content: url('../../assets/icons/svg/MainMap/calendar.svg');
               width: 2em;
               height: 1.5em;
               z-index: 1000 !important;
               position: absolute;
               inset-inline-start: 0.7em;
               top: 0.75em;
           }
           .triangle-calendar-wrapper{
               position: absolute;
               top:-51px;
               inset-inline-start: -0.5em;       
                @media(min-width:2000px) {
                    .nice-dates-day_date,
                    .nice-dates-week-header_day {
                        font-size: 1.1em !important;
                    }
                    .date-picker-container .nice-dates-navigation_current {
                        font-size: 1.1em !important;
                    }
                }
               .triangle {
                   position: absolute;
                   width: 0;
                   height: 0;
                   top: 8.3em;
                   inset-inline-start: 11.5em;
                   border-left: 10px solid transparent;
                   border-right: 10px solid transparent;
                   border-bottom: 10px solid $light-container-background;
                   z-index: 1000 !important;
               }
               .calendar {
                   position: absolute;
                   inset-inline-start: 3.2em;
                   top: 9.1em;
                   z-index: 100 !important;
                   .date-picker-container {
                       width: 20em;
                       background-color: $light-container-background !important;
                   }
               }
           }
            .Export-Wrapper .Calendar-Wrapper .triangle{
                top:0.7em;
            }
            .toggle-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                flex-wrap: wrap;
                height: 30px;
                background: $container-background 0% 0% no-repeat padding-box;
                $lower-shadow: 4px 4px 8px darken($container-background, 3%);
                $upper-shadow: -3px -3px 6px lighten($container-background, 4%);
                -webkit-appearance: none !important;
                box-shadow: $upper-shadow, $lower-shadow !important;
                -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
                border-radius: 5px;
                border: 2px solid transparent;
                outline: none;
            }
            .toggle-icon:not(.viewer):hover {
                background-color: $primary;
                border: 2px solid $primary-txt;
                transition: 0.3s ease-out;
            }
            @media (max-width: 800px){
                .toggle-icon{
                    width: 25px !important;
                    height: 25px !important;
                }
                .table-export{
                    width: 25px !important;
                    height: 25px !important;
                }
            } 
            .sorting-and-filtering-container .filter-view{
                width: calc(100% + 6rem);
                margin-inline-start: -6rem;
            }
            .table-actions {
                display: flex;
                gap: 14px;
                @media (max-width: 600px) {
                    gap: 4px;
                }

                .seasons-filter {
                    #action-btn {
                        background-color: $container-background;
                        height: 30px;
                        width: 30px;

                        @media (max-width: 800px){
                            height: 25px;
                            width: 25px;
                        }
                        
                        @media (hover: hover) { 
                            &:hover {
                                background: #73e4cc;
                            }
                        }  
                    }
            
                    .menu-container {
                        @media (max-width: 600px) {
                            left: -170px;
                            [dir="rtl"] & {
                                left: -70px
                              }
                        }
                    }
                }

                .sorting-and-filtering-container {
                    width: auto;
                }
            }
        }
        .card-body{
            padding-top:1em !important;
        } 
        .modalBackground .modalContainer div:has(p) p:nth-child(2){
            font-size: 0.9rem;
            color: #46AAA0;
            text-transform: none;
            margin-bottom: 1rem !important;
        }
    }
    .comparison-container-toggle-wrapper {
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
        align-items: stretch;
        margin: 0px;
        padding: 0px;
        .first-table-chart {
            width: 50%;
            position: relative;
            border-radius: 5px 0px 0px 5px;
            margin-inline-end: 2.5px;
            display: flex;
            height: 100%;
            .table-view{
                .calendar-button{
                    width: 360px;
                }
            }
        }
        .second-table-chart {
            width: 50%;
            position: relative;
            border-radius: 0px 5px 5px 0px;
            margin-inline-start: 2.5px;
            display: flex;
            height: 100%;
            .table-view{
                .calendar-button{
                    width: 360px;
                }
            }
        }
        .first-table-chart, .second-table-chart{
            .table-view, .chart-view{
                width: 100%;
            }
            flex: 1 50%;
            .chart-view .empty-state-graph svg {
                width: 26%;
                height: 100%;
            }
        }
        @media (max-width: 878px) {
            flex-direction: column !important;
            .first-table-chart, .second-table-chart {
                width: 100% !important;
            }
            .second-table-chart {
                margin-top: -13px;
            }
        }
        @media (max-width: 990px) {
            padding-inline: 1rem!important;
        }
    }
    .seasons-filter {
        .value-label {
            background: #02312C;
            width: auto;
            text-wrap: nowrap;
            padding-inline: 8px;
            &:hover {
                background: $primary;
            }
        }
        svg {
            height: 12px;
            width: 12px;
            path {
                fill: $primary;
            }
        }

        &:hover {
            path {
                fill: #165F59;
            }
        }
    }
}
@media (min-width: 600px) and (max-width: 1000px){
    .c-wrapper:has(.VegetationIndex_Container .table-view) .card-header {
        font-size: 0.9em !important;
    }
    .table-view .modalBackground .modalContainer {
        padding-bottom: 7%;
    }
}
@media (min-width: 1200px){
    .VegetationIndex_Container:has(.table-view) .card-header .calendar-button{
        font-size: 1.1em;
    }
}
@media(min-width:2000px) {
    .table-view .nice-dates-day_date,
    .table-view .nice-dates-week-header_day,
    .chart-view .nice-dates-day_date,
    .chart-view .nice-dates-week-header_day {
        font-size: 1.1em !important;
    }  
    .chart-view .date-picker-container .nice-dates-navigation_current,
    .chart-view .date-picker-container .nice-dates-navigation_current {
        font-size: 1.1em !important;
    }
}
[dir="rtl"] .chart-view{
    .card-header{
        .calendar-button {
            span {
                font-size: 1.1em;
            }
        }
    }
}  
[dir="rtl"] .table-view{
    .card-header{
        .calendar-button {
            span {
                font-size: 1em;
            }
        }
    }
}    