.info-tooltip {
  path:hover {
    fill: $primary;
  }
}

.tippy-box:has(.info-tooltip-content) {
  .tippy-content {
    background-color: #0a524b;
    color: $primary-txt;
    opacity: 0.95;
    max-width: unset;
    padding: 15px;
    max-height: 200px;
    overflow-y: scroll;
    text-align: left;
    [dir="rtl"] & {
      text-align: right;
    }
    @extend .regular-font;
  }

  .tippy-arrow {
    color: #0a524b;
  }
}

div:has(> .tippy-box) {
  pointer-events: auto !important;
}
