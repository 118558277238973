$sidebar-dark-color: $cta-txt !important;
$sidebar-dark-nav-link-color: $primary !important;
$sidebar-dark-nav-link-icon-color: $primary !important;
$sidebar-dark-nav-link-hover-color: $cta-txt !important;
$sidebar-dark-nav-link-hover-bg: $bubble-selection !important;
$sidebar-dark-nav-link-hover-icon-color: $cta-txt !important;
$sidebar-dark-nav-link-active-color: $cta-txt !important;
$sidebar-dark-nav-link-active-bg: $bubble-selection !default;
$sidebar-dark-nav-link-active-icon-color: $cta-txt !important;
$sidebar-dark-dropdown-toggle-active-color: $primary-txt !important;


.SideBar_Wrapper {
    .d-md-down-none {
        display: flex !important;

        .c-sidebar-brand-full {
            display: flex !important;

        }
    }
}

@media (max-width: 991.98px) {
    .c-sidebar-backdrop.c-show {
        opacity: 0.5;
    }

    .c-sidebar-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1030;
        width: 100vw;
        height: 100vh;
        background-color: #000015;
        transition: 0.3s;
    }
}


.c-sidebar .c-sidebar-minimizer {
    position: absolute !important;
    background-color: $cta-txt !important;
    //width: 1.5em !important;
    //height: 5em !important;
    width: 2.4em !important;
    height: 6.6em !important;
    border-radius: 0 5px 5px 0;
    margin-inline-start: 100%;
    background: url("../../assets/icons/svg/sidebar/collapse.svg") no-repeat;
    background-position: center;
    box-shadow: inset 3px 0px 15px -7px #042926;


    // top: 12vh;
    //margin-block-start: -90vh;
    // @media (min-width:1640px) {
    //     margin-block-start: -86vh;
    // }
    // @media (min-width:1920px) {
    //     margin-block-start: -87vh;
    // }
    // @media (min-width:2880px) {
    //     margin-block-start: -92vh;
    // }
    // @media (min-width:3800px) {
    //     margin-block-start: -94vh;
    // }
    // margin-block-start: -87vh;
    // &:hover{
    //     background-color: $primary !important;
    // }
    &::before {
        display: none !important;
    }

    [dir="rtl"] & {
        background: url('../../assets/icons/svg/sidebar/expand.svg') no-repeat;
        background-position: center;
        border-radius: 5px 0 0 5px;
        box-shadow: inset -3px 0px 15px -7px #042926;
    }
}

.c-sidebar-brand {
    background-color: $sidebar-dark !important;
    display: block !important;
    flex: none !important;
    align-items: none !important;
    justify-content: none !important;
    margin: auto !important;
    padding: 10% 0 !important;
}

[dir="ltr"] .c-sidebar-brand-full {
    width: 7.5em !important;
    /* height: 100% !important; */
    background: url('../../assets/icons/svg/SignIn/Logo-en.svg') no-repeat;
    background-size: contain;
    background-position: center;
}

[dir="rtl"] .c-sidebar-brand-full {
    width: 7.5em !important;
    /* height: 100% !important; */
    background: url('../../assets/icons/svg/SignIn/Logo-ar.svg') no-repeat;
    background-size: contain;
    background-position: center;
}

.c-sidebar {
    width: 12.8rem !important;
    background-color: $sidebar-dark !important;
    font-size: .75em !important;

    @media(min-height: 1000px) {
        font-size: 1.05em !important;
    }

    .c-sidebar-nav-link {
        padding-bottom: .7em !important;
        color: $sidebar-dark-nav-link-color;
        //font-family: nunito_semibold !important;
        //font-size: 1.1em;
        //font-size: 12px;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));

        img {
            padding-left: .7em !important;
            padding-right: .7em !important;
        }
    }

    .c-sidebar-nav-dropdown.c-show {
        .c-sidebar-nav-dropdown-toggle {
            background: $bubble-selection 0% 0% no-repeat padding-box !important;
            opacity: 1 !important;
            color: $cta-txt !important;
            text-transform: uppercase !important;
            font-weight: 700 !important;

            .img-top {
                display: none !important
            }

            .img-down {
                display: inline;
            }

            &::after {
                transform: rotate(0deg) !important;
                background-image: url('../../assets/icons/svg/sidebar/active/ddlArrow.svg') !important;
            }
        }

        .c-sidebar-nav-item {
            &:not(.disabled):hover {
                .c-sidebar-nav-link {
                    background: $sidebar-dark-dropdown-item-bg !important;
                    opacity: 1 !important;

                    //@extend .gadget-title;
                    @media(min-height: 1000px) {
                        font-size: 13px !important;
                    }

                    color: $primary-txt !important;
                    //font-family: nunito_semibold !important;
                    //font-size: 1em;
                    font-size:calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
                }

                .img-top {
                    display: none !important
                }

                .img-down {
                    display: inline;
                }
            }

            .active {
                //@extend .gadget-title;
                //font-family: nunito_semibold;
                font-weight: 600;
                text-transform: uppercase;
                color: $primary-txt;
                font-size: 11px;
                background: transparent linear-gradient(90deg, #85f1da, #1dae9f) !important;

                @media(min-height: 1000px) {
                    font-size: 11px !important;

                    &:hover {
                        font-size: 11px !important;
                        //font-family: nunito_semibold !important;
                        font-weight: 700 !important;
                    }
                }

                background: $sidebar-dark-dropdown-item-bg !important;
                opacity: 1 !important;
                color: $primary-txt !important;

                .img-top {
                    display: none !important
                }

                .img-down {
                    display: inline;
                }

                &:hover {
                    font-size: 11px !important;
                    //font-family: nunito_semibold !important;
                    font-weight: 700 !important;
                }
            }
        }
    }

    .c-sidebar-nav-dropdown-items {

        .c-sidebar-nav-item:not(.disabled) {
            .c-sidebar-nav-link {
                :hover {
                    border-left: 5px solid $primary !important;
                    font-size: 11px;
                    //font-family: 'nunito_semibold'
                }
            }
        }

        .c-sidebar-nav-link {
            img {
                margin-left: .5em !important;
            }
        }

        .active {
            border-left: 5px solid $primary !important;
        }
    }

    .c-sidebar-nav-dropdown-toggle {
        &:hover {
            background: $sidebar-dark-nav-link-hover-bg !important;
            opacity: 1 !important;
            color: $sidebar-dark-nav-link-hover-color !important;
            text-transform: uppercase !important;
            font-weight: 700 !important;

            .img-top {
                display: none !important
            }

            .img-down {
                display: inline;
            }

            &::after {
                background-image: url('../../assets/icons/svg/sidebar/active/ddlArrow.svg') !important;
            }
        }

        &::after {
            margin-left: 7px !important;
            background-image: url('../../assets/icons/svg/sidebar/inactive/ddlArrow.svg') !important;
        }
    }

    .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
    html:not([dir=rtl]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
        padding-left: .7em !important;
    }

    .c-sidebar-nav-link.active {
        background: transparent linear-gradient(90deg, #85f1da, #1dae9f) !important;
        color: #165F59 !important;
    }


    .c-sidebar-nav-item {
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));

        &:not(.disabled):hover {
            .c-sidebar-nav-link {
                background: $sidebar-dark-nav-link-hover-bg !important;
                opacity: 1 !important;
                color: $sidebar-dark-nav-link-hover-color !important;
                text-transform: uppercase;
                //font-weight: 700 !important;
                //font-size: 11px;
                font-family: nunito_extraBold;
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            }

            .img-top {
                display: none !important
            }

            .img-down {
                display: inline;
            }
        }

        .active {
            background: transparent $sidebar-dark-nav-link-active-bg !important;
            opacity: 1 !important;
            color: $sidebar-dark-nav-link-active-color !important;
            text-transform: uppercase;
            // font-weight: 700 !important;
            //font-size: 11px;
            font-family: nunito_extraBold;
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));

            //@extend .sidebar-link-active;
            .img-top {
                display: none !important
            }

            .img-down {
                display: inline;
            }
        }
    }

    .c-sidebar-brand {
        background-color: $sidebar-dark-nav-link-icon-color !important;
        padding-top: 25px;
        padding-bottom: 30px;
    }

    .small-upgrade-card {
        border: 2px solid $primary;
        border-radius: 5px;
        margin: 20px;
        padding: 16px;
        text-align: center;
        .proceed-button {
            height: auto;
            padding: 6px 12px;
            font-size: 13px;
          }
    }
}

.img-top {
    display: inline;
}

.img-down {
    display: none
}

[dir='rtl'] .c-sidebar .c-sidebar-nav-item:not(.disabled) .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    &::after {
        transform: rotate(0) !important;
    }
}

[dir=rtl] .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
*[dir=rtl] .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
    padding-right: 1.4em !important;
}

[dir=rtl] .c-sidebar-nav-dropdown-items {

    .c-sidebar-nav-item:not(.disabled) .c-sidebar-nav-link {
        &:hover {
            border-left: 0px solid $primary !important;
            border-right: 5px solid $primary !important;
        }
    }

    .active {
        border-right: 5px solid $primary !important;
        border-left: 0px solid $primary !important;
    }
}

@media (min-width: 992px) {

    .c-sidebar-minimized .c-sidebar-nav-item:not(.disabled) .c-sidebar-nav-link:hover,
    .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle:hover,
    .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle:hover {
        width: 56px !important;
    }

    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper,
    *[dir=rtl] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper {
        margin-left: 15.4em !important;
        margin-right: 0 !important;
    }

    [dir=rtl] .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper,
    *[dir=rtl] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper {
        margin-right: 15.4em !important;
        margin-left: 0em !important;
    }

    html:not([dir=rtl]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized~.c-wrapper,
    html:not([dir=rtl]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable~.c-wrapper,
    html:not([dir=rtl]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized~.c-wrapper,
    html:not([dir=rtl]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable~.c-wrapper {
        margin-left: 5.5em !important;
        margin-right: 0 !important;
    }

    [dir=rtl].c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized~.c-wrapper,
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable~.c-wrapper,
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized~.c-wrapper,
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable~.c-wrapper {
        margin-right: 5.5em !important;
        margin-left: 0em !important;
    }

    .c-sidebar-minimized .c-sidebar-brand-full {
        display: block !important;
        width: 3rem !important;
        padding: 25% 0 !important;
    }

    .c-sidebar-unfoldable:not(:hover),
    .c-sidebar-unfoldable:hover {
        background: $sidebar !important;
        width: 6em !important;

        .c-sidebar-nav li {
            margin-inline-start: 0em;

            a div {
                padding-inline-end: 2em;
            }
        }

        .c-sidebar-minimizer {
            // position: initial !important;
            background: url('../../assets/icons/svg/sidebar/expand.svg') no-repeat;
            background-position: center;
            bottom: auto !important;

            // @media (min-width:1640px) {
            //     margin-block-start: -89vh;
            // }
            // @media (min-width:1920px) {
            //     margin-block-start: -90vh;
            // }
            // @media (min-width:2880px) {
            //     margin-block-start: -95vh;
            // }
            // @media (min-width:3800px) {
            //     margin-block-start: -95vh;
            // }
            [dir="rtl"] & {
                background: url('../../assets/icons/svg/sidebar/collapse.svg') no-repeat;
                background-position: center;
            }
        }

        .c-sidebar-brand-full {
            width: 4em !important;
            /* height: 100% !important; */
            background: url('../../assets/icons/svg/SignIn/logoRes.svg') no-repeat;
            background-size: contain;
            background-position: center;
            display: block !important;
        }
    }
}

@media (min-width:1920px) and (min-width:1920px) {

    // .c-sidebar-minimizer{
    //     margin-block-start: -92vh;
    // }
    @media (min-width: 992px) {

        .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav li,
        .c-sidebar-unfoldable:hover .c-sidebar-nav li {
            margin-inline-start: 0;

            img {
                padding-left: 0 !important;
            }
        }

        .c-sidebar-unfoldable:not(:hover),
        .c-sidebar-unfoldable:hover {
            width: 4.5em !important;

            .c-sidebar-brand-full {
                width: 3em !important;
            }

            // .c-sidebar-minimizer{
            //     margin-block-start: -92vh;
            //     @media (min-width:2000px) {
            //         margin-block-start: -87vh;
            //     }
            // }
        }
    }
}

@media (min-width:2004px) and (min-width:2004px) {

    .c-sidebar .c-sidebar-nav-item .c-sidebar-nav-link,
    .c-sidebar .c-sidebar-nav-item .c-sidebar-nav-link.active,
    .c-sidebar .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-toggle {
        font-size: 12px;
    }
}

@media (min-width:768px) {}

[dir='rtl'] {
    .c-header .dropdown-item:hover {
        font-family: tajawal_medium;
        font-weight: 600;
    }

    .c-sidebar-nav-item,
    .c-sidebar .c-sidebar-nav-item:not(.disabled) .c-sidebar-nav-link,
    .c-sidebar .c-sidebar-nav-link.active {
        font-family: tajawal_medium !important;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));

        &:hover {
            font-family: tajawal_medium;
            font-weight: 600;
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            text-transform: none;
        }

        .active {
            font-family: tajawal_medium;
            font-weight: 600;
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
        }
    }
}

@media (hover: hover),
(-ms-high-contrast: none) {

    .c-sidebar .c-sidebar-nav-item.disabled .c-sidebar-nav-link:hover {
        color: #73E4CC !important;
        background: transparent !important;
        cursor: default;
    }
}