.pagination-container {
  display: flex;
  list-style-type: none;
  position: absolute;
  bottom: 0;
  padding-inline-start: 0 !important;

  .pagination-item {
    padding: 0px 6px;
    height: 20px;
    text-align: center;
    margin: auto 4px;
    color: #058376;
    font-weight: bold;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    border-color: #054D46;
    border-width: 3px;
    font-size: 13px;
    min-width: 10px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      // background-color:#73E4CC;
      cursor: pointer;
    }

    &.selected {
      background-color: #73E4CC;
    }

    .arrow {
      :hover {
        background-color: none;
      }

      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid #058376;
        ;
        border-top: 0.12em solid #058376;
        ;
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid #058376;
        ;
        border-top: 0.12em solid #058376;
        ;
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

[dir=rtl] .pagination-container {
  .pagination-item {
    .arrow.left {
      transform: rotate(45deg) translate(-50%);
    }

    .pagination-container .pagination-item .arrow.right {
      transform: rotate(-135deg);
    }
  }
}