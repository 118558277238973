.define-manual-meter-panel-container {
    .Panel {
        padding-inline: 30px;
        padding-block: 15px;

        ::-webkit-scrollbar {
            height: 100% !important;
            width: 7px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background: transparent;

        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #256F68;
            border-radius: 10px;
            width: 2px !important;
            cursor: pointer !important;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #348C83;

        }

        ::-webkit-scrollbar {
            display: block !important;
        }

        .tabs-container {
            margin-top: 5px;

            button {
                width: 50px;
                height: 50px;
                border: none;
                outline: none;
                background-color: #165F59;
                border-radius: 5px;
                margin-inline-end: 20px;
                box-shadow: -4px -4px 5px #067c71, 4px 4px 10px #0228249e !important;
                -webkit-box-shadow: -4px -4px 5px #067c71, 4px 4px 10px #0228249e !important;
            }

            .selected,
            button:not(.blocked):hover {
                background-color: #73E4CC;
                border: 2px solid #C4FFF9;
            }

            .blocked {
                opacity: 20%;
                pointer-events: none;
            }
        }

        .tab-content {
            height: 65vh;
            overflow-y: scroll;
            margin-bottom: 10px;



            .error {
                color: #F48585;
                margin-top: -20px;
                font-size: 0.9em;
            }

            .language-link {
                color: #73E4CC;
            }

            .languages-conversion {
                background-color: #14514C80;
                border-radius: 5px;
                padding-block: 10px;
                padding-inline: 20px;
                max-height: 200px;
                overflow-y: scroll;
                margin-top: 15px;

                .language-note {
                    svg {
                        margin-inline-end: 5px;
                    }
                }

                .language-item-container {
                    width: 100%;

                    form {
                        width: 80%;
                        margin-inline: 10px;

                        .input-group {
                            margin-top: 20px !important
                        }
                    }
                }

                .delete-button {
                    background-color: #145853;
                    -webkit-appearance: none;
                    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                    -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                    border: none;
                    outline: none;
                    margin-inline-start: 5px;
                    margin-top: 5px;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                }

                .drop-down-selector-container {
                    background-color: #145853;
                    padding-inline: 25px;
                    margin-inline-end: 5px;
                    margin-top: 5px;
                }
            }

            .Checkbox-Container {
                padding-inline-start: 5px;
            }

            .language-link:hover {
                text-decoration: underline;
                text-underline-offset: 2px;
            }

            .meter-environement-container {
                span {
                    margin-inline-end: 10px;
                    color: #C4FFF9;
                    margin-bottom: 5px;
                }

                .drop-down-selector-container .choices-list {
                    top: 3.5em;
                    left: 0;
                    width: 8em;
                }

                .drop-down-selector-container {
                    margin-inline-start: 5px;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    // min-width: 100px;
                    width: 8em;
                    background-color: #165F59;
                    padding-block: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    box-shadow: -4px -4px 5px #056259, 4px 4px 10px #02282480 !important;
                    -webkit-box-shadow: -4px -4px 5px #056259, 4px 4px 10px #02282480 !important;

                    &::before {
                        content: url('../../assets/icons/svg/ManualInput/manualEnv.svg');
                        width: 20px;
                        height: 20px;
                    }

                    .current-choice {
                        color: #73E4CC;
                    }
                }

                .drop-down-selector-container .choices-list {
                    background-color: #0D514C;
                }

                .drop-down-selector-container .triangle {
                    border-bottom: 10px solid #0D514C;
                    top: 2.9em;
                }
            }

            // .meter-environement-container {
            //     display: flex;
            //     flex-wrap: wrap;
            //     margin-top: 5px;

            //     span {
            //         margin-inline-end: 10px;
            //         color: #C4FFF9;
            //         margin-bottom: 5px;
            //     }

            //     .radio-button {
            //         background-color: #165F59;
            //         -webkit-appearance: none;
            //         box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            //         -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            //     }

            //     .radio-button-selection {
            //         background-color: #73E4CC !important;
            //     }
            //     .radio-button-element{
            //         margin-inline-start: 5px;
            //     }
            // }

            .bounds-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                form {
                    width: 100%;
                    margin-top: -25px;

                    span {
                        margin-inline-start: 20px;
                    }

                    .form-control:focus+span {
                        margin-inline-start: 0;
                    }
                }

                .input-wrapper {
                    display: flex;
                    flex-direction: column;
                    width: 45%;

                }
            }


        }

        .define-manual-meter-title {
            color: #C4FFF9;
            letter-spacing: 0.18px;
            text-transform: uppercase;
            //font-size: 1em
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
        }

        .input-group-prepend {
            display: flex;
            width: 30px;
            height: 30px;
            flex-wrap: wrap;
        }

        .tab-title {
            letter-spacing: 0px;
            color: #C4FFF9;
            text-transform: uppercase;
            //font-size: 0.9em;
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            margin-top: 10px
        }

        .tab-subtitle {
            letter-spacing: 0px;
            color: #46AAA0;
            //font-size: 0.9em
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
        }

        .proceed-button {
            width: 150px;
            margin-top: 0;
            margin-inline: auto;
        }

        .proceed-button.disabled {
            pointer-events: none;
        }
    }

}

.meter-map-selection-view {
    position: relative;
    height: 75%;
    border-radius: 5px;
    background-color: #14514CD8;
    margin-block: 10px;
    padding-inline: 25px;
    padding-top: 10px;
    font-size: 12px;
    padding-bottom: 5px;

    .mapboxgl-popup-anchor-top {
        max-width: 180px !important;
    }

    .mapboxgl-popup-tip {
        width: 0;
        height: 0;
        border: 7px solid transparent;
        z-index: 1;
        -webkit-align-self: center;
        align-self: center;
        border-top: none;
        border-bottom-color: #fff;
    }

    .mapboxgl-popup-content {
        padding: 0
    }

    #location-map-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 42% !important;
        border-radius: 5px;
    }

    &+div {
        font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
    }

    .labels-headers-container {
        padding-top: 44%;
        display: flex;
        flex-direction: row;
        color: #C4FFF9;
        letter-spacing: 0px;
        text-transform: uppercase;

        div {
            width: 150px;
            margin-inline-end: 25px;
        }
    }

    .labels-body-container {
        // margin-top:10px;
        // height: 150px;
        height: 42%;
        overflow-y: scroll;
        box-sizing: border-box;

        .label-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-height: 40px;

            form,
            .plot-name,
            .label {
                width: 150px;
            }

            form,
            .label {
                margin-inline-end: 25px;
            }

            .plot-name,
            .label {
                padding-top: 10px;
            }

            button {
                width: 25px;
                height: 25px;
                border: none;
                outline: none;
                background-color: #165F59;
                border-radius: 5px;
                -webkit-appearance: none;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                margin-inline-end: 15px;
            }
        }
    }
}

.depth-wrapper {
    form .input-group {
        margin: 12px 0;
    }
}

@media (max-width: 850px) {
    .define-manual-meter-panel-container .Panel .tab-content {
        height: 55vh;
    }

    .meter-map-selection-view .labels-headers-container {
        padding-top: 30%;
    }
}